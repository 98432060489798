import {matchPath, useLocation} from "react-router-dom";
import routes from "../../routes";

const useRouteDependentCardLink = (card) => {
  const location = useLocation();
  let m;
  if ((m = matchPath(location.pathname, {path: routes.milestone.path}))) {
    if (m.params.milestoneId === "level") {
      const mLvl = matchPath(location.pathname, {path: routes.sprint.path});
      if (mLvl) return routes.sprintWithCard.getUrl(mLvl.params.sprintId, card);
    } else if (m.params.milestoneId === "due") {
      const mDue = matchPath(location.pathname, {path: routes.dueDate.path});
      if (mDue) return routes.dueDateWithCard.getUrl(mDue.params.dayKey, card);
    } else {
      return routes.milestoneWithCard.getUrl(m.params.milestoneId, card);
    }
  }
  if (card.deck && matchPath(location.pathname, {path: routes.decks.path})) {
    return routes.deckWithCard.getUrl(card.deck, card);
  }
  return routes.handWithCard.getUrl(card);
};

export default useRouteDependentCardLink;
