import {forwardRef} from "react";
import {Link} from "react-router-dom";
import Ui from "../../components/ui2";
import {
  ButtonBehaviour,
  cx,
  uiStyles,
  rawButtonStyle,
  uiStyles as uiClasses,
  WithTooltip,
} from "@cdx/common";
import {XRow, XCol, XText} from "../../components/xui";
import {notificationTypesToUi} from "./card-diff-info";
import {animated} from "react-spring";
import Avatar from "../../components/Avatar";
import {api} from "../../lib/api";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import routes from "../../routes";
import {dayToDate, prettyWorkdaysForAccount, startOfDay} from "../../lib/date-utils";
import {notiDetailsStyles, pillStyles} from "./notifications.css";
import {
  getMilestoneState,
  msToDate,
  setMsThemeColor,
  ThemedMilestoneIcon,
} from "../milestones/milestone-utils";
import {CdxCropImgByFile} from "../../components/CdxImg";
import {addIntention} from "../../lib/last-intention";
import {pluralize} from "../../lib/utils";
import {
  Box,
  Col,
  DSIconBell,
  DSIconBlocked,
  DSIconConversation,
  DSIconReview,
  DSIconSnoozing,
  Row,
} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {ThemedSprintIcon, getSprintState, sprintLabel} from "../milestones/sprint-utils";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {DSDiscordConvoAwareAvatar} from "../card-container/sidebars/next-conversations/convo-utils";
import {DSAvatarStyles} from "../../components/DSAvatar/DSAvatar.css";
import DSAvatar from "../../components/DSAvatar/DSAvatar";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";

const resContextInfo = {
  comment: {
    icon: <DSIconConversation size={24} />,
    title: "Comment thread",
  },
  block: {
    icon: <Ui.Icon.Block size={20} className={uiClasses.color.blocked500} />,
    color: "blocked500",
    title: "Block conversation",
  },
  review: {
    icon: <Ui.Icon.Review size={20} className={uiClasses.color.review} />,
    color: "review",
    title: "Review",
  },
};

const toUser = (id) => api.getModel({modelName: "user", id});

export const getDismissIdsByType = {
  res: ({notification}) => ({
    type: "res",
    ids: [notification.resolvable.id],
  }),
  cardDiff: ({notification}) => ({type: "cardDiff", ids: [notification.card.id]}),
  subbedDeck: ({cardIds}) => ({type: "cardDiff", ids: cardIds}),
  targetDeck: ({cardIds}) => ({type: "cardDiff", ids: cardIds}),
  targetMilestone: ({cardIds}) => ({type: "cardDiff", ids: cardIds}),
  targetSprint: ({cardIds}) => ({type: "cardDiff", ids: cardIds}),
  dueCard: () => ({type: "dueCard", ids: []}),
};

const emptyInfo = {
  getUrl: () => "",
  icon: null,
  getChangers: () => [],
  getTitle: () => "deleted",
  getText: () => "",
  onDismiss: () => {},
};

const CardTitle = ({card}) => {
  const pTitle = card.parentCard && card.parentCard.$meta.get("title", null);
  return (
    <XRow sp={0} align="baseline">
      {pTitle && (
        <UnMarkedown className={pillStyles.parentCardPill} maxChars={16} title={pTitle}>
          {pTitle}
        </UnMarkedown>
      )}
      <UnMarkedown maxChars={50} title={card.title}>
        {card.title}
      </UnMarkedown>
    </XRow>
  );
};

const ConvoPillIcon = ({n, userId}) => {
  const {participants} = n.resolvable;
  const getUser = () => {
    if (n.unseenAuthors.length) return toUser(n.unseenAuthors[0]);
    const lastEntryAuthor = n.latestEntry?.author;
    if (lastEntryAuthor && lastEntryAuthor.id !== userId) return lastEntryAuthor;
    const firstNonDonePart = participants.find((p) => p.user?.id !== userId && !p.done)?.user;
    return firstNonDonePart || toUser(userId);
  };
  const getIcon = () => {
    const type = n.resolvable.$meta.get("context", "comment");
    if (n.isSnoozing) {
      let color = dsStyles.color.secondary;
      if (type === "review") color = uiClasses.color.review;
      if (type === "block") color = uiClasses.color.blocked500;
      return <DSIconSnoozing size={16} className={color} />;
    }
    if (type === "review") return <DSIconReview size={16} className={uiClasses.color.review} />;
    if (type === "block") return <DSIconBlocked size={16} className={uiClasses.color.blocked500} />;
    return null;
  };
  const icon = getIcon();
  return (
    <div className={uiClasses.position.relative}>
      <Avatar user={getUser()} size={35} />
      {icon && (
        <Col absolute style={{top: -10, right: -10}}>
          <Box colorTheme="gray750" rounded="full" pa="2px" elevation={100} bg="foreground">
            {icon}
          </Box>
        </Col>
      )}
    </div>
  );
};

const typeInfo = {
  res: ({notification: n}, {userId, showFacesExperiment}) => {
    if (n.$meta.isDeleted() || !n.resolvable || !n.resolvable.card) return emptyInfo;
    const {icon, color, title} = resContextInfo[n.resolvable.$meta.get("context", "comment")];
    return {
      dismissMessage: null,
      withUnseen: n.isParticipating && n.unseenEntryCount > 0,
      getUrl: (getCardUrl) =>
        getCardUrl({
          card: n.resolvable.card,
          targetPanel: "comments",
          resolvableId: n.resolvable.id,
        }),
      icon: showFacesExperiment ? (
        <ConvoPillIcon n={n} userId={userId} />
      ) : n.isSnoozing ? (
        <DSIconSnoozing size={24} className={uiClasses.color[color]} />
      ) : (
        icon
      ),
      getChangers: () =>
        n.isSnoozing
          ? []
          : n.unseenAuthors.length
            ? n.unseenAuthors.map(toUser)
            : n.latestEntry // TODO: figure out why latestEntry is `null`sometimes
              ? [n.latestEntry.author]
              : [],
      getTitle: () => <CardTitle card={n.resolvable.card} />,
      getText: () => {
        if (!n.isParticipating) return title;
        if (n.isLastParticipant && !n.resolvable.isClosed) return "You're the last participant";
        if (n.isSnoozing) return "Snoozing conversation";
        if (n.unseenEntryCount > 1) return `${n.unseenEntryCount} new comments`;
        if (n.resolvable.isClosed) return `Closed ${title}`;
        if (n.latestEntry === null) return title;
        return (
          <UnMarkedown className={notiDetailsStyles.comment} maxChars={50}>
            {n.latestEntry.content}
          </UnMarkedown>
        );
      },
      onDismiss: () => {
        const args = {resolvableIds: [n.resolvable.id], userId};
        return api.mutate.notifications.dismissResolvables(args);
      },
      resolvable: n.resolvable,
      card: n.resolvable.card,
    };
  },
  cardDiff: ({notification: n, types}, {userId}) => {
    if (n.$meta.isDeleted() || !n.card) return emptyInfo;
    const notiInfo = notificationTypesToUi[types[0]];
    if (!notiInfo) return emptyInfo;
    const {icon, renderIcon, title, getTitle} = notiInfo;
    return {
      getUrl: (getCardUrl) => getCardUrl({card: n.card, targetPanel: "history"}),
      icon: renderIcon ? renderIcon(n.changes) : icon,
      getTitle: () => <CardTitle card={n.card} />,
      getText: () => (getTitle ? getTitle(n) : title),
      getChangers: () => n.changers.map(toUser),
      dismissMessage: null,
      onDismiss: () => api.mutate.notifications.dismissCardDiffs({cardIds: [n.card.id], userId}),
      card: n.card,
    };
  },

  dueCard: ({card}, {root}) => {
    return {
      getUrl: (getCardUrl) => getCardUrl({card}),
      icon: <DSIconBell className={pillStyles.dueCardPill} size={24} />,
      getTitle: () => <CardTitle card={card} />,
      getText: () => {
        if (!card.dueDate) return "Not due";
        const targetDay = dayToDate(card.dueDate);
        const days = card.dueDate && prettyWorkdaysForAccount({account: root.account, targetDay});
        return (
          <>
            Due date {targetDay < startOfDay(new Date()) ? "was" : "is"} {days}
          </>
        );
      },
      getChangers: () => [],
      dismissMessage: `To dismiss, mark this card as done or move due date into the future`,
    };
  },

  subbedDeck: ({notificationCount, deck, changers, cardIds}, {userId}) => {
    return {
      getUrl: () => routes.deck.getUrl(deck),
      intention: {type: "setOrder", payload: {prop: "changes", isReversed: false}},
      pillBgFile: deck.coverFile,
      icon: <div className={notiDetailsStyles.cardCountInDeck}>{notificationCount}</div>,
      getTitle: () => deck.title,
      getText: () => `${notificationCount} changed cards`,
      getChangers: () => changers.map(toUser),
      dismissMessage: null,
      onDismiss: () => api.mutate.notifications.dismissCardDiffs({cardIds, userId}),
    };
  },

  targetDeck: ({notificationCount, deck, changers, cardIds}, {userId}) => {
    if (!deck) return emptyInfo;
    return {
      getUrl: () => routes.deck.getUrl(deck),
      intention: {type: "setOrder", payload: {prop: "changes", isReversed: false}},
      pillBgUrl: deck.coverFile && deck.coverFile.$meta.get("url", null),
      icon: (
        <XRow className={notiDetailsStyles.cardCountInDeck} align="center">
          <Ui.Icon.ArrowRight size={9} />
          <span>{notificationCount}</span>
        </XRow>
      ),
      getTitle: () => deck.title,
      getText: () => `${notificationCount} cards moved here`,
      getChangers: () => changers.map(toUser),
      dismissMessage: null,
      onDismiss: () => api.mutate.notifications.dismissCardDiffs({cardIds, userId}),
    };
  },

  targetSprint: ({notificationCount, sprint, changers, cardIds}, {root, userId}) => {
    return {
      getUrl: () => routes.sprint.getUrl(sprint.accountSeq),
      intention: {type: "setOrder", payload: {prop: "changes", isReversed: false}},
      icon: (
        <>
          <Col absolute align="center" justify="center" inset={0}>
            <ThemedSprintIcon
              size={24}
              theme={setMsThemeColor(sprint.sprintConfig)}
              sprintState={getSprintState(sprint)}
              className={iconStyles.setSize["30px"]}
              onDark
            />
          </Col>
          <Row align="center" relative>
            <Box className={notiDetailsStyles.msText} top="-3px">
              {notificationCount}
            </Box>
          </Row>
        </>
      ),
      getTitle: () => {
        return <span>{sprintLabel(sprint)}</span>;
      },
      getText: () => `${notificationCount} cards added`,
      getChangers: () => changers.map(toUser),
      dismissMessage: null,
      onDismiss: () => api.mutate.notifications.dismissCardDiffs({cardIds, userId}),
    };
  },
  targetMilestone: ({notificationCount, milestone, changers, cardIds}, {root, userId}) => {
    return {
      getUrl: () => routes.milestone.getUrl(milestone.accountSeq),
      intention: {type: "setOrder", payload: {prop: "changes", isReversed: false}},
      icon: (
        <>
          <Col absolute align="center" justify="center" inset={0}>
            <ThemedMilestoneIcon
              theme={setMsThemeColor(milestone)}
              className={iconStyles.setSize["30px"]}
              milestoneState={getMilestoneState(root.account, milestone)}
              onDark
            />
          </Col>
          <XRow align="center" relative>
            <Box className={notiDetailsStyles.msText}>{notificationCount}</Box>
          </XRow>
        </>
      ),
      getTitle: () => {
        const milestoneDate = milestone.date !== "string" && msToDate(milestone);
        const days =
          milestoneDate &&
          prettyWorkdaysForAccount({account: root.account, targetDay: milestoneDate});
        return (
          <span>
            {milestone.name}
            {days && <span style={{fontWeight: "normal"}}> {days}</span>}
          </span>
        );
      },
      getText: () => `${notificationCount} cards added`,
      getChangers: () => changers.map(toUser),
      dismissMessage: null,
      onDismiss: () => api.mutate.notifications.dismissCardDiffs({cardIds, userId}),
    };
  },
};

const Pill = ({children, withUnseen, bgFile}) => {
  return (
    <div className={uiStyles.position.relative}>
      {withUnseen && <div className={pillStyles.pill.breatheBox} />}
      <XRow
        align="center"
        justify="center"
        relative
        className={cx(pillStyles.pill.base, withUnseen && pillStyles.pill.unseenComments)}
      >
        {bgFile ? (
          <>
            <CdxCropImgByFile
              file={bgFile}
              width={41}
              height={41}
              asBackground
              className={dsStyles.rounded.full}
            />
            <div className={uiClasses.position.relative}>{children}</div>
          </>
        ) : (
          children
        )}
      </XRow>
    </div>
  );
};

const Changers = ({changers, card, resolvable}) => {
  const users = changers.filter(Boolean).reverse();
  return (
    <Box display="flex" flexDir="row-reverse" align="center">
      {users.map((user, idx) =>
        card && resolvable ? (
          <DSDiscordConvoAwareAvatar
            key={user.$meta.get("id", idx)}
            className={DSAvatarStyles.stacked}
            card={card}
            resolvable={resolvable}
            user={user}
            size={16}
          />
        ) : (
          <DSAvatar
            className={DSAvatarStyles.stacked}
            key={user.$meta.get("id", idx)}
            user={user}
            size={16}
          />
        )
      )}
    </Box>
  );
};

const Panel = forwardRef(
  ({style, getTitle, getChangers, getText, dismissMessage, onDismiss, card, resolvable}, ref) => {
    const changers = getChangers();
    return (
      <XRow className={cx(pillStyles.panel, colorThemes.gray50)} ref={ref} style={style}>
        <ButtonBehaviour
          type="button"
          className={cx(rawButtonStyle, pillStyles.closeButton)}
          disabled={dismissMessage}
          tooltip={dismissMessage}
          tooltipProps={dismissMessage && {placement: "left"}}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return onDismiss();
          }}
        >
          <Ui.Icon.Close />
        </ButtonBehaviour>
        <XRow fillParent pl={1} className={pillStyles.panelBody} minWidth align="center">
          <XCol minWidth>
            <XText size={1} preset="bold" noOverflow>
              {getTitle()}
            </XText>
            <XRow align="center" sp={1} pt={0}>
              {changers.length > 0 && (
                <Changers changers={changers} card={card} resolvable={resolvable} />
              )}
              <XText lineHeight="tight" noOverflow size={1} color="gray600">
                {getText()}
              </XText>
            </XRow>
          </XCol>
        </XRow>
      </XRow>
    );
  }
);

const AnimatedPanel = animated(Panel);

const NotificationPill = forwardRef((props, ref) => {
  const {notiInfo, getCardUrl, style, openReveal, root, userId, showFacesExperiment} = props;
  const {
    getUrl,
    icon,
    title,
    getChangers,
    getTitle,
    getText,
    dismissMessage,
    withUnseen,
    pillBgFile,
    onDismiss,
    card,
    intention,
    resolvable,
  } = typeInfo[notiInfo.type](notiInfo, {root, userId, showFacesExperiment});
  return (
    <Link
      className={pillStyles.container}
      to={getUrl(getCardUrl)}
      ref={ref}
      style={style}
      onClick={intention && (() => addIntention(intention))}
    >
      <Pill icon={icon} withUnseen={withUnseen} bgFile={pillBgFile}>
        {icon}
      </Pill>
      {openReveal((revealProps) => (
        <AnimatedPanel
          title={title}
          getChangers={getChangers}
          style={revealProps}
          getTitle={getTitle}
          getText={getText}
          dismissMessage={dismissMessage}
          onDismiss={onDismiss}
          card={card}
          root={root}
          resolvable={resolvable}
        />
      ))}
    </Link>
  );
});

export default NotificationPill;

export const NonShownPill = ({count, style}) => (
  <WithTooltip
    tooltip={`${pluralize(
      count,
      "more undismissed notification"
    )}. To reveal, dismiss notifications above.`}
    options={{placement: "left"}}
    as={animated.div}
    style={style}
    className={pillStyles.nonShownPillContainer}
  >
    +{count}
  </WithTooltip>
);
