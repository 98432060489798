import {CdxLexicalState} from "../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import {useSlashCommand} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import {FocusProps} from "./useFocusMode";
import {textUpdateSelectionHandler} from "../../components/RichTextarea/Lexical/CdxTypeAheadLexicalPlugin";

export const FocusModeCommandPlugin = ({
  focusProps,
  contentField,
}: {
  focusProps: FocusProps;
  contentField: CdxLexicalState;
}) => {
  useSlashCommand({
    key: "focusMode",
    label: "Enter Focus Mode",
    keywords: "focus mode open",
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(() => {
        focusProps.setActive({
          editorState: e.getEditorState().clone(),
        });
      });
    },
    groupKey: "card",
  });
};

export default FocusModeCommandPlugin;
