import {useState} from "react";
import {useDropZone, useDragItem} from "@codecks/dnd";
import {api} from "../../lib/api";
import DeckSelect, {DECK_SELECT_MODAL_WIDTH} from "../../components/DeckSelect";
import {useModalWithData} from "../../components/Modals";
import messenger from "../../lib/messenger";
import {XRow} from "../../components/xui";
import routes from "../../routes";
import {moveCardsToDeck} from "../../lib/cross-project-cards-or-decks";
import Tab from "./Tab";
import {searchActions, useSearchStore} from "../../features/search/useSearch";
import useBling from "../../lib/hooks/useBling";
import {cx, useGlobalKeyPress, useToggle} from "@cdx/common";
import MilestonePicker from "../../features/milestones/MilestonePicker";
import useMutation from "../../lib/hooks/useMutation";
import {
  canAddHandToQueueErrors,
  canDiscardHandFromQueue,
  isCardInHandQueue,
} from "../../features/hand-queue/hand-queue-utils";
import {mainNavigationStyles as styles} from "./arena.css";
import {toDateStr} from "../../lib/date-utils";
import {getRawSelectedProjects} from "../../lib/hooks/useSelectedProjects";
import {HelpCenterButton} from "../../features/help-center/HelpCenter";
import {Col, DSIconCheck} from "@cdx/ds";
import {useCardResizeArea} from "../../components/Card/dragged-card-resize";
import useFeatureFlag, {FEATURE_FLAGS} from "../../components/useFeatureFlag";
import {hasPermissionToGuardCard} from "../../lib/permissions";

const DecksButton = ({root, isActive}) => {
  const [pickDeckForCardIds, setPickDeckForCardIds] = useState(null);
  const [showDeckSelector, {on, off}] = useToggle();

  const handleDeckPick = (deckId) => {
    return moveCardsToDeck(pickDeckForCardIds, deckId).then((ok) => {
      if (ok) {
        messenger.send(
          <span>
            {pickDeckForCardIds.length === 1 ? "Card was" : "Cards were"} moved to{" "}
            <b>{api.getModel({modelName: "deck", id: deckId}).title}</b>
          </span>
        );
      }
    });
  };

  const closeDeckSelector = () => {
    setPickDeckForCardIds(null);
    off();
  };

  const handleCardDrop = ({item}) => {
    if (!item) return;
    if (item.data.cardInfos.some(({getCard}) => !hasPermissionToGuardCard(root, getCard()))) return;
    on();
    setPickDeckForCardIds(item.data.cardInfos.map((ci) => ci.cardId));
  };

  const renderDeckPicker = () => {
    const deckIds = new Set();
    if (pickDeckForCardIds) {
      pickDeckForCardIds.forEach((id) => {
        const card = api.getModel({modelName: "card", id});
        if (card.deck) deckIds.add(card.deck.id);
      });
    }
    return (
      <DeckSelect
        root={root}
        onChange={handleDeckPick}
        value={deckIds.size === 1 ? [...deckIds][0] : null}
        onClose={closeDeckSelector}
      />
    );
  };

  const {isOver, dragItem, ref: dropRef} = useDropZone({type: "CARD", onDrop: handleCardDrop});
  const renderModal = useModalWithData(showDeckSelector, {
    onClose: closeDeckSelector,
    hideClose: true,
    width: DECK_SELECT_MODAL_WIDTH,
  });

  const validDragItems =
    dragItem &&
    dragItem.data.cardInfos.some(({getCard}) => hasPermissionToGuardCard(root, getCard()));
  const dragCardsHaveDeck =
    validDragItems && dragItem.data.cardInfos.some(({getCard}) => getCard().deck);
  return (
    <>
      {renderModal(renderDeckPicker)}
      <Tab
        to={routes.decks.getUrl()}
        active={isActive}
        index={1}
        canDrop={Boolean(validDragItems)}
        isDropOver={validDragItems && isOver}
        ref={dropRef}
      >
        {validDragItems ? (dragCardsHaveDeck ? "Change deck" : "Add to Deck") : "Decks"}
      </Tab>
    </>
  );
};

const HandQueueButton = ({isActive, root}) => {
  const [blingRef, setMsg] = useBling();
  const handleDropSuccess = () => setMsg(<DSIconCheck size={16} />);

  const onDropCard = () => {
    const cardIds = dragItem.data.cardInfos.map((c) => c.cardId);
    if (allInQueue) {
      if (canDiscardSome) {
        api.mutate.handQueue.removeCards({cardIds}).then(handleDropSuccess);
      }
    } else if (canAddSome) {
      api.mutate.handQueue
        .addCardsToOwner({cardIds, accountId: root.account.id})
        .then(handleDropSuccess);
    }
  };

  const {dragItem, isOver, ref} = useDropZone({
    type: "CARD",
    onDrop: onDropCard,
  });

  const cards = dragItem && dragItem.data.cardInfos.map((ci) => ci.getCard());
  const allInQueue =
    cards && cards.every((card) => isCardInHandQueue({card, account: root.account}));
  const canDiscardSome = allInQueue && cards.some(canDiscardHandFromQueue);
  const canAddSome =
    cards && cards.some((card) => !canAddHandToQueueErrors(card, root.loggedInUser.id, root));

  const getText = () => {
    if (allInQueue) {
      if (canDiscardSome) {
        return "Discard from hand";
      }
    } else if (canAddSome) {
      return "Add to hand";
    }
    return "Hand";
  };

  const handleClick = () => {
    if (isActive) searchActions.setFilters([]);
  };
  const text = getText();
  return (
    <Tab
      to={routes.hand.getUrl()}
      handActive={isActive}
      index={0}
      canDrop={dragItem && text !== "Hand"}
      isDropOver={isOver && text !== "Hand"}
      isRemoveDrop={allInQueue}
      smallText={text.length > 5}
      ref={ref}
      onClick={handleClick}
    >
      <div ref={blingRef}>{text}</div>
    </Tab>
  );
};

const MilestoneButton = ({isActive, to, root}) => {
  const [pickMsForCardIds, setPickMsForCardIds] = useState(null);
  const [showMsSelector, {on, off}] = useToggle();
  const [updateCards] = useMutation("cards", "bulkUpdate");

  const closeMsSelector = () => {
    setPickMsForCardIds(null);
    off();
  };

  const handleMsPick = (milestoneId) => {
    return updateCards({milestoneId, ids: pickMsForCardIds}).then(() => {
      messenger.send(
        <span>{pickMsForCardIds.length === 1 ? "Card was" : "Cards were"} moved to milestone.</span>
      );
      closeMsSelector();
    });
  };

  const handleCardDrop = ({item}) => {
    on();
    setPickMsForCardIds(item.data.cardInfos.map((ci) => ci.cardId));
  };

  const renderMsPicker = () => {
    const count = pickMsForCardIds ? pickMsForCardIds.length : 0;

    return (
      <MilestonePicker
        account={root.account}
        onlySelectedProjects
        onChange={handleMsPick}
        title={`Pick milestone${count > 1 ? ` for ${count} cards` : ""}`}
        allowNone
        root={root}
      />
    );
  };

  const {isOver, dragItem, ref: dropRef} = useDropZone({type: "CARD", onDrop: handleCardDrop});
  const renderModal = useModalWithData(showMsSelector, {
    onClose: closeMsSelector,
    hideClose: true,
    width: 400,
  });

  return (
    <>
      {renderModal(renderMsPicker)}

      <Tab active={isActive} index={2} to={to} canDrop={dragItem} isDropOver={isOver} ref={dropRef}>
        Timeline
      </Tab>
    </>
  );
};

const useNextMilestone = (root) => {
  const {loggedInUser, account} = root;
  if (!loggedInUser || !account.$meta.isLoaded || !account.milestonesEnabled)
    return {nextMilestone: null, isPinned: false};
  const todayStr = toDateStr(new Date());

  const maybePinned = loggedInUser.$meta.find("pinnedMilestoneNext", {
    accountId: account.id,
    $or: [
      {
        milestone: {
          date: {op: "gte", value: todayStr, isDeleted: false},
        },
      },
      {
        sprint: {
          endDate: {op: "gte", value: todayStr, isDeleted: false},
        },
      },
    ],
  });
  const maybeMs = maybePinned?.milestone ?? null;
  if (maybeMs && maybeMs.$meta.isLoaded) return {nextMilestone: maybeMs, isPinned: true};
  const maybeSprint = maybePinned?.sprint ?? null;
  if (maybeSprint && maybeSprint.$meta.isLoaded) return {nextSprint: maybeSprint, isPinned: true};

  const selectedProjects = getRawSelectedProjects(root);
  const selProjIds = selectedProjects.map((p) => p.$meta.get("id", null)).filter(Boolean);

  const nextMilestone = account.$meta.first("milestones", {
    ...(selProjIds.length ? {milestoneProjects: {projectId: selProjIds}} : {}),
    date: {op: "gte", value: todayStr},
    isDeleted: false,
    $order: ["date", "id"],
  });
  const nextSprint = account.$meta.first("sprints", {
    ...(selProjIds.length ? {sprintConfig: {sprintProjects: {projectId: selProjIds}}} : {}),
    endDate: {op: "gte", value: todayStr},
    isDeleted: false,
    $order: ["endDate", "sprintConfigId"],
  });
  if (!nextMilestone && !nextSprint) return {isPinned: false};
  // dayToDateStr(nextSprint.endDate) >= dayToDateStr(nextMilestone.date)
  if (!nextSprint) {
    return {nextMilestone, isPinned: false};
  } else {
    return {nextSprint, isPinned: false};
  }
};

const MilestoneArea = ({root, activeTab, history}) => {
  const {nextMilestone, nextSprint} = useNextMilestone(root);
  const getTo = () => {
    if (nextMilestone) return routes.milestone.getUrl(nextMilestone.accountSeq);
    if (nextSprint) return routes.sprint.getUrl(nextSprint.accountSeq);
    return routes.milestones.getUrl();
  };
  const to = getTo();
  useGlobalKeyPress({
    code: "Digit3",
    fn: () => history.push(to),
    description: "Go to milestones",
    withShift: true,
  });
  return <MilestoneButton isActive={activeTab === "milestones"} to={to} root={root} />;
};

const VisionBoardButton = ({isActive}) => (
  <Tab active={isActive} index={3} to={routes.visionBoard.getUrl()}>
    Vision
  </Tab>
);

const MainNavigation = ({root, activeTab, history}) => {
  const searchOpen = useSearchStore((s) => s.isFocussed);

  useGlobalKeyPress({
    code: "Digit1",
    fn: () => history.push(routes.hand.getUrl()),
    description: "Go to your hand",
    withShift: true,
  });
  useGlobalKeyPress({
    code: "Digit2",
    fn: () => history.push(routes.decks.getUrl()),
    description: "Go to decks",
    withShift: true,
  });

  const withVisionBoard = useFeatureFlag(root.account, FEATURE_FLAGS.visionBoard);

  const draggedCard = useDragItem("CARD");

  const nodeRef = useCardResizeArea({cardScale: 0.25, reach: 100, disabled: !draggedCard});

  return (
    <XRow
      className={cx(styles.default, searchOpen && styles.focussedSearch)}
      align="end"
      ref={nodeRef}
    >
      <HandQueueButton isActive={activeTab === "hand"} root={root} />
      <DecksButton root={root} isActive={activeTab === "decks"} />
      {(root.account.milestonesEnabled || root.account.sprintsEnabled) && (
        <MilestoneArea activeTab={activeTab} history={history} root={root} />
      )}
      {withVisionBoard && <VisionBoardButton isActive={activeTab === "visionBoard"} />}
      <Col pl={2} justify="center" alignSelf="stretch">
        <HelpCenterButton root={root} />
      </Col>
    </XRow>
  );
};
export default MainNavigation;
