import {KeyboardEvent, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {Box, CSSProps, Col, Row, css} from "../Box/Box";
import {DSInput} from "../DSForm/DSInput";
import {DSSpinner} from "../DSIcon/DSSpinner";
import {VirtualItem, useVirtualizer} from "@tanstack/react-virtual";
import {makeScrollable} from "../../utils/makeScrollable";

const ROW_HEIGHT = 16 + 16 + 4 + 1;

type OptionRowProps<T extends any> = {
  virtualRow: VirtualItem<any>;
  option: T;
  selected: boolean;
  isCurrent: boolean;
  onClick: () => void;
  children: ReactNode;
};
const OptionRow = <T extends any>(props: OptionRowProps<T>) => {
  const {virtualRow, children, selected, isCurrent, onClick} = props;
  const styleProps: CSSProps = selected
    ? {colorTheme: "active600", bg: "foreground"}
    : {
        useHoverBg: "true",
        useHoverColor: "true",
      };

  return (
    <div
      role="button"
      onClick={onClick}
      style={{height: `${virtualRow.size - 1}px`, transform: `translateY(${virtualRow.start}px)`}}
      data-cdx-clickable
      className={css({
        ...styleProps,
        position: "absolute",
        display: "flex",
        top: "0",
        left: "0",
        width: "100%",
        align: "center",
        rounded: 4,
        color: "primary",
        cursor: "pointer",
      })}
    >
      <Row px="8px" py="8px" width="100%" sp="8px">
        <Box
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          size={14}
          lineHeight="16px"
          flex="auto"
        >
          {children}
        </Box>
        {isCurrent && (
          <Box ml="auto" size={12} color="secondary">
            current
          </Box>
        )}
      </Row>
    </div>
  );
};

const SectionRow = (props: {
  children: ReactNode;
  virtualRow: VirtualItem<any>;
  optionCount: number;
}) => {
  const {children, virtualRow, optionCount} = props;
  return (
    <Col
      style={{
        height: `${virtualRow.size - 1 + optionCount * ROW_HEIGHT}px`,
        top: virtualRow.start,
      }}
      absolute
      left="0"
      width="100%"
    >
      <Col
        position="sticky"
        top="0"
        style={{height: `${virtualRow.size - 1}px`}}
        zIndex={1}
        bg="foreground"
        color="primary"
        justify="end"
        pb="4px"
        textTransform="uppercase"
        size={12}
        bold
        width="100%"
      >
        {children}
      </Col>
    </Col>
  );
};
const CreateRow = (props: {
  children: ReactNode;
  virtualRow: VirtualItem<any>;
  label: ReactNode;
  selected: boolean;
  onClick: () => void;
}) => {
  const {children, virtualRow, label, selected, onClick} = props;
  const styleProps: CSSProps = selected
    ? {colorTheme: "active600", bg: "foreground"}
    : {
        useHoverBg: "true",
        useHoverColor: "true",
      };
  return (
    <div
      role="button"
      onClick={onClick}
      style={{height: `${virtualRow.size - 1}px`, transform: `translateY(${virtualRow.start}px)`}}
      data-cdx-clickable
      className={css({
        ...styleProps,
        position: "absolute",
        display: "flex",
        top: "0",
        left: "0",
        width: "100%",
        align: "center",
        rounded: 4,
        color: "primary",
        cursor: "pointer",
      })}
    >
      <Row px="8px" py="8px" width="100%" sp="8px">
        <Box
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          size={14}
          lineHeight="16px"
          flex="auto"
        >
          {children}
        </Box>
        <Box ml="auto" size={12} color="secondary">
          {label}
        </Box>
      </Row>
    </div>
  );
};

type DataRowProps<T extends any> = {
  virtualRow: VirtualItem<any>;
  items: Item<T>[];
  selIdx: number;
  onClickOption: (key: any, index: number, type: "create" | "option", value: T | null) => void;
  searchString?: string;
} & Pick<DSListSelectorProps<T>, "renderOption" | "currentOptionKey" | "createOpts">;
const DataRow = <T extends any>(props: DataRowProps<T>) => {
  const {
    virtualRow,
    items,
    renderOption,
    selIdx,
    currentOptionKey,
    onClickOption,
    createOpts,
    searchString,
  } = props;
  const item = items[virtualRow.index];
  switch (item.type) {
    case "create": {
      return (
        <CreateRow
          virtualRow={virtualRow}
          label={createOpts?.label || "create"}
          selected={virtualRow.index === selIdx}
          onClick={() => onClickOption(searchString, virtualRow.index, "create", null)}
        >
          {searchString}
        </CreateRow>
      );
    }
    case "option":
      return (
        <OptionRow
          virtualRow={virtualRow}
          selected={virtualRow.index === selIdx}
          option={item.value}
          isCurrent={currentOptionKey === virtualRow.key}
          onClick={() => onClickOption(virtualRow.key, virtualRow.index, "option", item.value)}
        >
          {renderOption(item.value)}
        </OptionRow>
      );
    case "section":
      return (
        <SectionRow virtualRow={virtualRow} optionCount={item.optionCount ?? 0}>
          {item.value}
        </SectionRow>
      );
    default:
      return <div>unknown</div>;
  }
};

type ListProps<T extends any> = Pick<
  DSListSelectorProps<T>,
  "renderOption" | "optionToKey" | "currentOptionKey" | "createOpts"
> & {
  items: Item<T>[];
  scrollNode: HTMLElement;
  selIdx: number;
  onClickOption: (key: any, index: number, type: "option" | "create", value: T | null) => void;
  searchString?: string;
};
const List = <T extends any>(props: ListProps<T>) => {
  const {
    items,
    optionToKey,
    renderOption,
    scrollNode,
    selIdx,
    currentOptionKey,
    onClickOption,
    createOpts,
    searchString,
  } = props;

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollNode,
    estimateSize: () => ROW_HEIGHT,
    overscan: 2,
    getItemKey: (idx: number) => {
      const item = items[idx];
      return item.type === "option" ? optionToKey(item.value) : `s:${item.key}`;
    },
    rangeExtractor: (range) => {
      const start = Math.max(range.startIndex - range.overscan, 0);
      const end = Math.min(range.endIndex + range.overscan, range.count - 1);
      const arr: number[] = [];
      let prevIdx = -1;
      for (let i = start; i <= end; i++) {
        const item = items[i];
        if (item.type === "option") {
          if (prevIdx !== item.idxOfSection) {
            if (item.idxOfSection! < start) {
              arr.push(item.idxOfSection!);
            }
            prevIdx = item.idxOfSection!;
          }
        }
        arr.push(i);
      }

      return arr;
    },
  });

  useEffect(() => {
    rowVirtualizer.scrollToIndex(selIdx, {align: "center", behavior: "auto"});
  }, [selIdx, rowVirtualizer]);

  const toKey = (key: any, item: Item<T>) => {
    if (item.type === "option") {
      return `${item.sectionKey}:${key}`;
    } else {
      return item.key;
    }
  };

  return (
    <Box
      width="100%"
      absolute
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
        <DataRow
          key={toKey(virtualRow.key, items[virtualRow.index])}
          items={items}
          renderOption={renderOption}
          virtualRow={virtualRow}
          selIdx={selIdx}
          currentOptionKey={currentOptionKey}
          onClickOption={onClickOption}
          createOpts={createOpts}
          searchString={searchString}
        />
      ))}
    </Box>
  );
};

const findCurrentOptionOrZero = <T extends any>(
  searchString: string,
  itemsWithSearchString: WithSearchString<T>[],
  currOption: T | undefined,
  allowCreation: boolean
): {nextIndex: number; foundPrevSelected: boolean; foundItem: Item<T> | null} => {
  const lowerSearch = searchString.toLowerCase();
  const filtered = lowerSearch
    ? transformAndFilter(itemsWithSearchString, searchString, allowCreation)
    : itemsWithSearchString.map((i) => i.item);
  if (filtered.length === 0) {
    return {nextIndex: 0, foundPrevSelected: false, foundItem: null};
  }
  const foundIndex = filtered.findIndex((i) => currOption === i.value);
  const minIndex = filtered.findIndex((i) => i.type !== "section");
  return {
    nextIndex: foundIndex > -1 ? foundIndex : minIndex,
    foundPrevSelected: foundIndex !== -1,
    foundItem: filtered[Math.max(minIndex, foundIndex)],
  };
};

type SectionItem = {
  type: "section";
  value: ReactNode;
  key: string | number;
  optionCount?: number;
};
type CreationItem = {
  key: string;
  type: "create";
  value?: undefined;
};
type Item<T> =
  | {type: "option"; value: T; idxOfSection?: number | null; sectionKey?: string | number}
  | SectionItem
  | CreationItem;
type WithSearchString<T> = {item: Item<T>; searchString: string | null};

export type DSListItem<T> = Item<T>;

const transformAndFilter = <T extends any>(
  itemsWithSearchString: WithSearchString<T>[],
  searchString: string | null,
  allowCreation: boolean
): Item<T>[] => {
  const lowerSearch = searchString?.toLowerCase();
  const items: Item<T>[] = [];
  let currSection: null | {item: SectionItem; idx: number} = null;
  let idx = 0;
  let exactMatch = false;
  for (const {item, searchString: itemSearch} of itemsWithSearchString) {
    if (item.type === "option") {
      const lower = itemSearch!.toLowerCase();
      if (!lowerSearch || lower.indexOf(lowerSearch) !== -1) {
        if (lower === lowerSearch) exactMatch = true;
        if (currSection) {
          item.idxOfSection = currSection.idx;
          item.sectionKey = currSection.item.key;
          currSection.item.optionCount = (currSection.item.optionCount ?? 0) + 1;
        } else {
          item.idxOfSection = undefined;
          item.sectionKey = undefined;
        }
        items.push(item);
        idx += 1;
      }
    } else if (item.type === "section") {
      if (currSection) {
        if (!currSection.item.optionCount) {
          items.pop();
          idx -= 1;
        }
      }
      item.optionCount = 0;
      currSection = {item, idx};
      items.push(item);
      idx += 1;
    }
  }
  if (currSection && !currSection.item.optionCount) items.pop();
  if (searchString && allowCreation && !exactMatch) items.push({type: "create", key: "$create"});
  return items;
};

const DSListSelectorWithLoadedOptions = <T extends any>(
  props: Omit<DSListSelectorProps<T>, "getOptions"> & {items: Item<T>[]}
) => {
  const {
    items: allItems,
    optionToKey,
    renderOption,
    currentOptionKey,
    autoFocus,
    onConfirm,
    confirmOnNavigation,
    initalSelectionKey,
    label,
    createOpts,
  } = props;
  const [searchString, setSearchString] = useState("");
  const [scrollNode, setScrollNode] = useState<HTMLElement | null>(null);
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });

  const itemsWithSearchString = useMemo(() => {
    return allItems.map((i) => ({
      item: i,
      searchString:
        i.type === "option" ? propsRef.current.optionToSearchString(i.value).toLowerCase() : null,
    }));
  }, [allItems]);

  const allowCreation = Boolean(createOpts);

  const filteredItems = useMemo(() => {
    return transformAndFilter(itemsWithSearchString, searchString, allowCreation);
  }, [itemsWithSearchString, searchString, allowCreation]);

  const minSelIdx = Math.max(
    0,
    filteredItems.findIndex((i) => i.type !== "section")
  );

  const [selIdx, setSelIdx] = useState(() =>
    initalSelectionKey !== undefined
      ? Math.max(
          minSelIdx,
          filteredItems.findIndex(
            (i) => i.type === "option" && optionToKey(i.value) === initalSelectionKey
          )
        )
      : minSelIdx
  );

  const count = filteredItems.length;
  const currSelItem = filteredItems[selIdx];

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown": {
        let nextIdx = Math.min(selIdx + 1, count - 1);
        if (nextIdx < count - 1) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "section") nextIdx += 1;
        }
        setSelIdx(nextIdx);
        if (onConfirm && confirmOnNavigation) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "option") {
            onConfirm(optionToKey(selItem.value), "navigation", selItem.value);
          } else if (selItem.type === "create" && createOpts?.toKey) {
            onConfirm(createOpts.toKey(searchString), "navigation", null);
          }
        }
        e.preventDefault();
        break;
      }
      case "ArrowUp": {
        const minIdx = filteredItems.findIndex((i) => i.type !== "section");
        let nextIdx = Math.max(selIdx - 1, minIdx, 0);
        if (nextIdx > minIdx) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "section") nextIdx -= 1;
        }
        setSelIdx(nextIdx);
        if (onConfirm && confirmOnNavigation) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "option") {
            onConfirm(optionToKey(selItem.value), "navigation", selItem.value);
          } else if (selItem.type === "create" && createOpts?.toKey) {
            onConfirm(createOpts.toKey(searchString), "navigation", null);
          }
        }
        e.preventDefault();
        break;
      }
      case "Enter": {
        if (currSelItem.type === "option") {
          const selOpt = currSelItem.value;
          if (onConfirm) {
            onConfirm(optionToKey(selOpt), "enter", selOpt);
            e.preventDefault();
          }
        } else if (currSelItem.type === "create") {
          if (createOpts?.onCreate) {
            createOpts.onCreate(searchString);
            e.preventDefault();
          }
        }
        break;
      }
    }
  };

  const handleClickOption = (key: any, index: number, type: "create" | "option", value: any) => {
    setSelIdx(index);
    if (type === "option") {
      if (onConfirm) onConfirm(key, "click", value);
    } else {
      if (createOpts?.onCreate) createOpts.onCreate(key);
    }
  };

  return (
    <Col sp="8px">
      {label && (
        <Box color="secondary" size={12} textTransform="uppercase" bold>
          {label}
        </Box>
      )}
      <DSInput
        size="sm"
        value={searchString}
        autoFocus={autoFocus}
        onChange={(next) => {
          setSearchString(next);
          const {foundPrevSelected, nextIndex, foundItem} = findCurrentOptionOrZero(
            next,
            itemsWithSearchString,
            currSelItem?.type === "option" ? currSelItem.value : undefined,
            allowCreation
          );
          setSelIdx(nextIndex);
          if (onConfirm && confirmOnNavigation && foundItem) {
            if (foundItem.type === "option") {
              if (!foundPrevSelected) {
                onConfirm(optionToKey(foundItem.value), "navigation", foundItem.value);
              }
            } else if (foundItem.type === "create" && createOpts?.toKey) {
              onConfirm(createOpts.toKey(next), "navigation", null);
            }
          }
        }}
        onKeyDown={handleKeyDown}
        className={css({width: "100%"})}
      />
      <Col
        sp="4px"
        rounded={4}
        style={{height: count * ROW_HEIGHT}}
        minHeight="24px"
        maxHeight="20rem"
        className={makeScrollable()}
        relative
        ref={setScrollNode}
      >
        {!scrollNode ? (
          <Col align="center" justify="center" flex="auto" />
        ) : filteredItems.length === 0 ? (
          <Col align="center" justify="center" flex="auto">
            <Box size={14} color="secondary" textAlign="center">
              No options found
            </Box>
          </Col>
        ) : (
          <List
            items={filteredItems}
            optionToKey={optionToKey}
            renderOption={renderOption}
            scrollNode={scrollNode}
            selIdx={selIdx}
            currentOptionKey={currentOptionKey}
            onClickOption={handleClickOption}
            createOpts={createOpts}
            searchString={createOpts && searchString}
          />
        )}
      </Col>
    </Col>
  );
};

type ConfirmHandler<T> = (
  key: string,
  mode: "enter" | "click" | "navigation",
  value: T | null
) => unknown | Promise<unknown>;

export type DSListSelectorProps<T extends any> = {
  renderOption: (t: T) => ReactNode;
  optionToSearchString: (t: T) => string;
  optionToKey: (t: T) => any;
  currentOptionKey?: any;
  autoFocus?: boolean;
  onConfirm?: ConfirmHandler<T>;
  confirmOnNavigation?: boolean;
  initalSelectionKey?: any;
  label?: string;
  createOpts?: {
    label: ReactNode;
    onCreate: (term: string) => unknown | Promise<unknown>;
    toKey?: (term: string) => any;
  };
} & (
  | {getOptions: () => T[] | null; getItems?: undefined}
  | {getItems: () => Item<T>[] | null; getOptions?: undefined}
);
export const DSListSelector = <T extends any>({
  getOptions,
  getItems,
  ...rest
}: DSListSelectorProps<T>) => {
  const getValues = (): Item<T>[] | null => {
    if (getOptions) {
      return getOptions()?.map((value) => ({type: "option", value})) ?? null;
    } else {
      return getItems();
    }
  };
  const items = getValues();
  if (!items) {
    return (
      <Col align="center" justify="center" flex="auto">
        <DSSpinner size={20} />
      </Col>
    );
  } else {
    return <DSListSelectorWithLoadedOptions {...rest} items={items} />;
  }
};
