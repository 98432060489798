import sortBy from "lodash/sortBy";
import {hasPermissionToAccessAllProjects} from "../permissions";

/**
 *
 * @param {import("../../cdx-models/Root").Root} root
 * @returns {Record<import("../../cdx-models/Project").ProjectId, number | null>}
 */
export const getProjectOrderIdx = (root) => {
  const {loggedInUser, account} = root;
  const projectOrders =
    account.$meta.isLoaded && loggedInUser
      ? loggedInUser.$meta.find("projectOrders", {accountId: account.id})
      : [];
  return projectOrders.reduce((m, o) => {
    m[o.projectId] = o.$meta.get("sortIndex", null);
    return m;
  }, {});
};

/**
 * Only returns projects a user has explicit access. I.e no public projects for guests
 * @param {import("../../cdx-models/Root").Root} root
 * @param {import("../../cdx-models/utils/extended-fields").UserProjectRole} projectRole
 * @returns {import("../../cdx-models/Project").Project[]}
 */
export const getMyProjects = (root, projectRole) => {
  const {account, loggedInUser: user} = root;
  if (!user) {
    // return public projects if not logged in
    return account.projects;
  }
  const accessToAll = hasPermissionToAccessAllProjects(root, {fallback: null});
  if (accessToAll === null) return [];
  if (accessToAll) {
    return account.projects;
  } else {
    return user.$meta
      .find("withProjectAccess", {
        ...(projectRole && {projectRole}),
        project: {visibility: "default", accountId: account.$meta.get("id", null)},
      })
      .map((pa) => pa.project);
  }
};

/**
 *
 * @param {import("../../cdx-models/Root").Root} root
 * @returns {import("../../cdx-models/Project").ProjectId[]}
 */
export const getMySelectedProjectIds = (root) => {
  const explicitSelections = getRawSelectedProjects(root);
  const selProjects = explicitSelections.length ? explicitSelections : getMyProjects(root);
  return selProjects.map((p) => p.$meta.get("id", null)).filter(Boolean);
};

/**
 *
 * @param {import("../../cdx-models/Root").Root} root
 * @param {import("../../cdx-models/Project").Project[]} projects
 * @returns {import("../../cdx-models/Project").Project[]}
 */
export const orderProjects = (root, projects) => {
  const projectIdToOrder = getProjectOrderIdx(root);
  return sortBy(projects, [(p) => p && projectIdToOrder[p.id], "id"]);
};

/**
 *
 * @param {import("../../cdx-models/Root").Root} root
 * @returns {import("../../cdx-models/Project").Project[]}
 */
export const useOrderedSelectedProjects = (root) => {
  return orderProjects(root, getSelectedProjects(root));
};

export const getOrderedSelectedProjects = useOrderedSelectedProjects;

/**
 * Note: when using this one, make sure to exclude archived/deleted projects!
 * @param {import("../../cdx-models/Root").Root} root
 * @returns {import("../../cdx-models/Project").Project[]}
 */
export const getRawSelectedProjects = (root) => {
  const {account, loggedInUser} = root;
  if (loggedInUser === null || !account.$meta.isLoaded) return [];
  return loggedInUser.$meta
    .find("projectSelections", {accountId: account.id})
    .map((ps) => ps.project)
    .filter(Boolean);
};

/**
 *
 * @param {import("../../cdx-models/Root").Root} root
 * @returns {import("../../cdx-models/Project").Project[]}
 */
export const getSelectedProjects = (root) => {
  const explicitSelections = getRawSelectedProjects(root);
  return explicitSelections.length ? explicitSelections : getMyProjects(root);
};

export default getSelectedProjects;
