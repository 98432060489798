import {XPlainButton, XPush} from "@cdx/common";
import {isCardInHandQueue} from "../hand-queue/hand-queue-utils";
import {api} from "../../lib/api";
import confirm from "../../lib/confirm";
import messenger from "../../lib/messenger";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {Box, Col, Row} from "@cdx/ds";
import {getBlockingCards} from "../dependencies/dependency-utils";
import {CardLink} from "../activity-feed/shared";
import {useRoot} from "../../lib/mate/mate-utils";

const checkCards = (cardIds) =>
  waitForResultPromise(() => {
    const freshCards = cardIds.map((cardId) => api.getModel({modelName: "card", id: cardId}));
    return {
      blockedCardIds: freshCards.filter((freshCard) => freshCard.hasBlockingDeps).map((c) => c.id),
      onHandCount: freshCards.filter((card) =>
        isCardInHandQueue({card, account: api.getRoot().account})
      ).length,
    };
  });

const Dialog = ({blockedCardIds, onCancel, onConfirm}) => {
  const sourceCards = blockedCardIds
    .map((id) => api.getModel({modelName: "card", id}))
    .filter(Boolean);
  const deps = sourceCards.reduce((set, card) => {
    getBlockingCards(card).forEach((c) => set.add(c));
    return set;
  }, new Set());
  const root = useRoot();
  return (
    <Col sp="16px">
      <Box color="primary">
        There are still unfinished dependencies:{" "}
        {[...deps].map((card) => (
          <>
            <CardLink card={card} root={root} inline />{" "}
          </>
        ))}
      </Box>
      <Box color="primary">Are you sure you are ready to start?</Box>
      <Row sp="16px" align="center">
        <XPlainButton onClick={onCancel}>Cancel</XPlainButton>
        <XPush />
        <XPlainButton color="red" size="xl" onClick={onConfirm}>
          Ignore dependencies and start card{blockedCardIds.length === 1 ? "" : "s"}
        </XPlainButton>
      </Row>
    </Col>
  );
};

export const performStartAction = async ({cardIds}) => {
  const {blockedCardIds, onHandCount} = await checkCards(cardIds);
  if (blockedCardIds.length > 0) {
    const ok = await confirm({
      title: blockedCardIds.length === 1 ? "This card is locked" : "Cards are locked",
      comp: Dialog,
      compProps: {blockedCardIds},
    });
    if (!ok) return false;
  }
  await api.mutate.cards.bulkUpdate({ids: cardIds, status: "started"});
  const nextHandCount = await waitForResultPromise(() => {
    const freshCards = cardIds.map((cardId) => api.getModel({modelName: "card", id: cardId}));
    return freshCards.filter((card) => isCardInHandQueue({card, account: api.getRoot().account}))
      .length;
  });
  const diff = nextHandCount - onHandCount;
  if (diff > 0) messenger.send(`${diff === 1 ? "Card was" : `${diff} cards were`} added to hand.`);
  return true;
};
