import {forwardRef, ReactNode} from "react";
import {WithLabel} from "./FieldWithLabel";
import {cx, inputStyles} from "..";
import {Col, css, Row, Text} from "@cdx/ds";

const valueIntoDom = (value: any): string | number =>
  typeof value === "string" || typeof value === "number" ? value : JSON.stringify(value);

type RadioGroupOption<T> = {
  value: T;
  label: string;
  desc?: string;
  postfix?: ReactNode;
  disabled?: boolean;
  postfixBelow?: boolean;
};

const ContainerComp = ({children, vertical}: {children: ReactNode; vertical: boolean}) =>
  vertical ? (
    <Col sp="16px">{children}</Col>
  ) : (
    <Row sp="16px" wrap align="center">
      {children}
    </Row>
  );

export type RadioGroupProps<T> = {
  showingErrors: boolean;
  onChange: (value: any) => void;
  options: RadioGroupOption<T>[];
  name?: string;
  value: T | null;
  withDescription?: boolean;
  vertical?: boolean;
  className?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value" | "checked" | "name">;
export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps<any>>((props, ref) => {
  const {
    showingErrors,
    onChange,
    options,
    name,
    value,
    withDescription,
    vertical: rawVertical,
    className,
    ...rest
  } = props;

  const vertical = Boolean(rawVertical || withDescription);

  return (
    <ContainerComp vertical={vertical}>
      {options.map(
        ({value: optValue, label: optLabel, desc: optDesc, postfix, disabled, postfixBelow}, i) => (
          <Row
            sp="12px"
            align={vertical ? "start" : "center"}
            key={optValue}
            pb={postfixBelow ? "24px" : 0}
          >
            <Row sp="8px" as="label" align="start">
              <input
                type="radio"
                name={name}
                ref={i === 0 ? ref : undefined}
                value={valueIntoDom(optValue)}
                checked={value === optValue}
                onChange={() => onChange(optValue)}
                className={css({size: 14}, inputStyles.checkbox, className)}
                disabled={rest.disabled || disabled}
                {...rest}
              />
              <Col sp="16px">
                <Col sp="4px">
                  <Text type="label14" color="primary">
                    {optLabel}
                  </Text>

                  {withDescription && optDesc && (
                    <Text type="prose14" color="secondary">
                      {optDesc}
                    </Text>
                  )}
                </Col>
                {postfixBelow}
              </Col>
            </Row>
            {postfix}
          </Row>
        )
      )}
    </ContainerComp>
  );
});

type RadioGroupWithLabelProps<T> = RadioGroupProps<T> & {
  label: string;
  hint?: string;
  hasPendingValidation?: boolean;
  showErrors?: any[];
};
export const RadioGroupWithLabel = forwardRef<HTMLInputElement, RadioGroupWithLabelProps<any>>(
  ({showErrors, name, label, hint, hasPendingValidation, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <RadioGroup name={name} {...rest} ref={ref} />
    </WithLabel>
  )
);

// value = {opt1: false, opt2: true}
// options = [{value: "opt1", label: "Option 1"}]

type CheckboxGroupProps<T> = {
  showingErrors: boolean;
  onChange: (value: any) => void;
  options: RadioGroupOption<T>[];
  name: string;
  value: any;
  vertical: boolean;
  className: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value" | "checked" | "name">;

export const CheckboxGroup = forwardRef<HTMLInputElement, CheckboxGroupProps<any>>(
  ({showingErrors, onChange, options, name, value, vertical, className, ...rest}, ref) => {
    return (
      <ContainerComp vertical={vertical}>
        {options.map(({value: optValue, label: optLabel}, i) => (
          <Row sp="8px" as="label" key={optValue}>
            <input
              ref={i === 0 ? ref : undefined}
              type="checkbox"
              name={optValue}
              checked={value[optValue] || false}
              onChange={(e) => onChange({...value, [optValue]: e.target.checked})}
              className={cx(inputStyles.checkbox, className)}
              {...rest}
            />
            <Text type="label14" color="primary">
              {optLabel}
            </Text>
          </Row>
        ))}
      </ContainerComp>
    );
  }
);

type CheckboxGroupWithLabelProps<T> = CheckboxGroupProps<T> & {
  label: string;
  hint?: string;
  hasPendingValidation?: boolean;
  showErrors?: any[];
};
export const CheckboxGroupWithLabel = forwardRef<
  HTMLInputElement,
  CheckboxGroupWithLabelProps<any>
>(({showErrors, name, label, hint, hasPendingValidation, ...rest}, ref) => (
  <WithLabel
    label={label}
    name={name}
    showErrors={showErrors}
    hint={hint}
    hasPendingValidation={hasPendingValidation}
  >
    <CheckboxGroup name={name} {...rest} ref={ref} />
  </WithLabel>
));
