import {Col, css} from "../Box/Box";
import {CSSProperties, ReactNode} from "react";

export const DSFieldLabel = ({children, fieldId}: {children: ReactNode; fieldId?: string}) => (
  <label
    htmlFor={fieldId}
    className={css({
      color: "secondary",
      textType: "label11Caps",
      minHeight: "24px",
      display: "flex",
      align: "center",
    })}
  >
    {children}
  </label>
);

export type DSFieldProps = {
  children: ReactNode;
  errors?: ReactNode[];
  hint?: ReactNode;
  className?: string;
  style?: CSSProperties;
  dontReserverErrorSpace?: boolean;
} & (
  | {
      label: ReactNode;
      fieldId: string;
    }
  | {label?: undefined; fieldId?: undefined}
);

export const DSField = (props: DSFieldProps) => {
  const {
    children,
    errors = [],
    hint,
    label,
    fieldId,
    className,
    style,
    dontReserverErrorSpace,
  } = props;
  return (
    <Col sp="8px" className={className} style={style}>
      {label && <DSFieldLabel fieldId={fieldId}>{label}</DSFieldLabel>}
      {children}
      {dontReserverErrorSpace && errors.length === 0 ? null : (
        <Col
          sp="4px"
          lineHeight="tight"
          minTextLineHeight={1}
          setSizeVar={12}
          setLineHeightVar="tight"
          colorTheme={errors.length > 0 ? "alert25" : undefined}
          size={12}
          color="secondary"
        >
          {errors.length > 0
            ? errors.map((e, idx) => <div key={idx}>{e}</div>)
            : hint
              ? hint
              : null}
        </Col>
      )}
    </Col>
  );
};
