import {ClickOutside, XTextButton, XCol, SpawnAnchoredOverlay, DefaultOverlay} from "@cdx/common";
import Ui from "./ui2";

const renderInlineModal = ({
  onClose,
  renderFn,
  anchorZIndex,
  presenceProps,
  style: overlayStyle,
  anchorPosition,
  overlayStyleByUser,
  ...rest
}) => (
  <DefaultOverlay
    anchorZIndex={anchorZIndex}
    anchorPosition={anchorPosition}
    style={{
      ...overlayStyle,
      left: overlayStyle.left + 25,
      top: overlayStyle.top - (anchorPosition.bottom - anchorPosition.top),
      maxWidth: overlayStyleByUser.width ? "100%" : 275,
      minWidth: 200,
      ...overlayStyleByUser,
    }}
    pa={4}
    presenceProps={presenceProps}
    {...rest}
  >
    <ClickOutside onClickOutside={onClose}>
      {(handlers) => (
        <div {...handlers}>
          <XCol absolute style={{top: 25, right: 25, zIndex: 2}}>
            <XTextButton square onClick={onClose}>
              <Ui.Icon.Close size={18} />
            </XTextButton>
          </XCol>
          {renderFn(onClose)}
        </div>
      )}
    </ClickOutside>
  </DefaultOverlay>
);

const InlineModal = ({children, isOpen, onClose, renderFn, overlayStyle = {}}) => (
  <SpawnAnchoredOverlay
    isOpen={isOpen}
    forcePlacement
    placement="bottom"
    distanceFromAnchor={-25}
    anchorAlignment="end"
    speed={{stiffness: 500, damping: 25}}
    renderOverlay={(p) =>
      renderInlineModal({...p, onClose, renderFn, overlayStyleByUser: overlayStyle})
    }
    backdrop
  >
    {children}
  </SpawnAnchoredOverlay>
);

export default InlineModal;
