import {useState, useEffect} from "react";
import {useHistory, matchPath, useLocation, Route} from "react-router-dom";

const getWizardInfo = {
  nux2020_one: () =>
    import(/* webpackChunkName: "Wizard_nux2020_one" */ "./fullscreen/nux2020_one/routes"),
  nux2020_two: () =>
    import(/* webpackChunkName: "Wizard_nux2020_two" */ "./fullscreen/nux2020_two/routes"),
  nux2020_discord: () =>
    import(/* webpackChunkName: "Wizard_nux2020_discord" */ "./fullscreen/nux2020_discord/routes"),
  nux2022_invites: () =>
    import(/* webpackChunkName: "Wizard_nux2022_invites" */ "./fullscreen/nux2022_invites/routes"),
  nux2022_invites_and_avatar: () =>
    import(
      /* webpackChunkName: "Wizard_nux2022_invites_and_avatar" */ "./fullscreen/nux2022_invites_and_avatar/routes"
    ),
  nux2023_source: () =>
    import(/* webpackChunkName: "Wizard_nux2023_source" */ "./fullscreen/nux2023_source/routes"),
};

const routesToRouteComps = ({routes, ...rest}) => (
  <>
    {Object.entries(routes).map(([key, info]) => (
      <Route key={key} path={info.path} exact children={<info.component {...rest} />} />
    ))}
  </>
);

const useRoutes = (name, setCheckForRedirect) => {
  const [routeInfo, setRouteInfo] = useState({name, routes: null});
  useEffect(() => {
    if (name) {
      const infoGetter = getWizardInfo[name];
      if (!infoGetter) return;
      infoGetter().then((mod) => {
        setRouteInfo({routes: mod.default, name});
        setCheckForRedirect(true);
      });
    }
  }, [name, setCheckForRedirect]);

  return name === routeInfo.name ? routeInfo.routes : null;
};

const useFullscreenWizard = (root) => {
  const history = useHistory();
  const {pathname} = useLocation();
  const [checkForRedirect, setCheckForRedirect] = useState(false);

  const activeWizards = root.account
    ? root.account.$meta.find("wizards", {
        finishedAt: null,
        $order: "createdAt",
      })
    : [];

  const activeWizard = activeWizards.find((w) => {
    const wName = w.$meta.get("name", null);
    w.$meta.get("currentStep", null);
    return wName in getWizardInfo;
  });
  const name = activeWizard && activeWizard.$meta.get("name", null);
  const currentStep = activeWizard && activeWizard.$meta.get("currentStep", null);

  const routes = useRoutes(name, setCheckForRedirect);

  useEffect(() => {
    if (routes && currentStep && checkForRedirect) {
      const routeInfo = routes[currentStep];
      if (!routeInfo) {
        console.warn(`Couldn't find path for wizard step ${currentStep}`);
      } else {
        const isOnPath = matchPath(pathname, {path: routeInfo.path, exact: true});
        if (!isOnPath) {
          history.replace(routeInfo.getUrl());
        }
      }
      setCheckForRedirect(false);
    }
  }, [routes, checkForRedirect, currentStep, history, pathname]);

  if (!activeWizard) return {isLoading: false, routes: null};
  if (!name || !currentStep) return {isLoading: true, routes: null};
  if (!routes) return {isLoading: true, routes: null};

  return {
    isLoading: false,
    routes: routesToRouteComps({
      routes,
      root,
      wizardId: activeWizard.id,
      history,
      wizardData: activeWizard.data,
    }),
  };
};

export default useFullscreenWizard;
