import {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {rawButtonStyle} from "../buttons/button.css";
import cx from "../cx";
import {isWithMetaKey} from "../device-utils";
import XCol from "../xui/XCol";
import {WithLabel} from "./FieldWithLabel";
import {toggleButtonStyles as styles} from "./form.css";
import {WithTooltip} from "../overlay/WithTooltip";
import UiHandlerContext from "@cdx/ds/components/DSForm/UiHandlerCtx";

const BaseToggleButton = forwardRef(
  ({checked, onChange, disabled: rawDisabled, onDark, onMetaClick, className, ...rest}, ref) => {
    const [pending, setPending] = useState(false);
    const unmountedRef = useRef();
    const {onButtonError} = useContext(UiHandlerContext);
    useEffect(
      () => () => {
        unmountedRef.current = true;
      },
      []
    );
    const handleClick = (e) => {
      e.stopPropagation();
      const clickFn = isWithMetaKey(e) && onMetaClick ? onMetaClick : onChange;
      const retVal = clickFn(!checked);
      if (retVal && typeof retVal.then === "function") {
        setPending(true);
        retVal.then(
          () => !unmountedRef.current && setPending(false),
          (error) => {
            onButtonError(error);
            if (!unmountedRef.current) setPending(false);
          }
        );
      }
    };
    const disabled = pending ? true : rawDisabled;
    return (
      <button
        ref={ref}
        {...rest}
        type="button"
        disabled={disabled}
        onClick={disabled || !onChange ? undefined : handleClick}
        className={cx(
          className,
          rawButtonStyle,
          styles.container.default,
          ...(onDark
            ? [
                styles.container.dark,
                checked ? styles.container.darkChecked : styles.container.darkUnchecked,
              ]
            : [
                styles.container.light,
                checked ? styles.container.lightChecked : styles.container.lightUnchecked,
              ]),

          disabled && styles.container.disabled
        )}
        aria-label="Toggle"
      >
        <XCol align={checked ? "end" : "start"}>
          <div className={styles.nipple} />
        </XCol>
      </button>
    );
  }
);

/**
 * @type React.FC<{
 * tooltip?: string
 * tooltipProps?: any
 * checked?: boolean
 * onChange?: (checked: boolean) => void
 * disabled?: boolean
 * onDark?: boolean
 * onMetaClick?: (e: any) => void
 * className?: string
 * } & React.ButtonHTMLAttributes<HTMLButtonElement>>
 */
export const ToggleButton = forwardRef(({tooltip, tooltipProps, ...rest}, ref) =>
  tooltip ? (
    <WithTooltip
      tooltip={tooltip}
      options={{...tooltipProps, targetIsDisabled: rest.disabled}}
      as={BaseToggleButton}
      {...rest}
      ref={ref}
    />
  ) : (
    <BaseToggleButton {...rest} ref={ref} />
  )
);

export const ToggleButtonWithLabel = forwardRef(
  ({showErrors, name, label, hint, hasPendingValidation, value, className, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
      className={className}
    >
      <ToggleButton checked={value} {...rest} ref={ref} />
    </WithLabel>
  )
);

export const ToggleButtonWithoutLabel = forwardRef(
  ({showErrors, name, hasPendingValidation, value, ...rest}, ref) => (
    <ToggleButton checked={value} {...rest} ref={ref} />
  )
);
