import memoizeOne from "memoize-one";
import {userHasPermissionToOwnHand} from "../../lib/permissions";
import {hasChildCards} from "../workflows/workflow-utils";

const userSetMemoizers = {};

export const isCardInHandQueue = ({card, account}) => {
  const userId = card.assignee && card.assignee.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  if (!userId || !cardId) return;
  let userMemo = userSetMemoizers[userId];
  if (!userMemo) {
    const getAllQueueEntriesAsSet = (entries) => {
      return new Set(
        entries.map((e) => e.$meta.get("card", null, {forceRelIds: true})).filter(Boolean)
      );
    };
    userMemo = userSetMemoizers[userId] = memoizeOne(getAllQueueEntriesAsSet);
  }
  return userMemo(account.$meta.find("queueEntries", {userId, cardDoneAt: null})).has(cardId);
};

const userPosMapMemoizers = {};

export const getCardPosInHandQueue = ({card, account}) => {
  const userId = card.assignee && card.assignee.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  if (!userId || !cardId) return;
  let userMemo = userPosMapMemoizers[userId];
  if (!userMemo) {
    const getAllQueueEntriesAsSet = (entries) => {
      return new Map(
        entries.map((e) => [e.$meta.get("card", null, {forceRelIds: true}), e.sortIndex + 1])
      );
    };
    userMemo = userPosMapMemoizers[userId] = memoizeOne(getAllQueueEntriesAsSet);
  }
  return userMemo(account.$meta.find("queueEntries", {userId, cardDoneAt: null})).get(cardId);
};

/**
 *
 * @param {{
 *   card: import("../../cdx-models/Card").Card
 *   root: import("../../cdx-models/Root").Root
 * }} opts
 * @returns {{
 *   isActive: boolean
 *   disabled: boolean
 *   tooltip: string
 * }}
 */
export const getBookmarkInfo = ({card, root}) => {
  const {loggedInUser} = root;
  const meId = loggedInUser && loggedInUser.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  const isActive = meId && cardId && isCardBookmarked({card, root});
  if (!isActive) return {disabled: false, tooltip: "Bookmark card", isActive};

  if (!card.deck) {
    if (card.visibility === "default") {
      return {
        isActive,
        disabled: true,
        tooltip:
          "Can't remove bookmark for non-archived private cards. Archive card or move it to deck for removing the bookmark.",
      };
    } else if (card.visibility === "archived") {
      return {
        isActive,
        disabled: false,
        tooltip:
          "Click to remove from bookmarks. You'll be able to retrieve this card by searching for 'private' and 'archived'.",
      };
    }
  }
  return {isActive, disabled: false, tooltip: "Card is bookmarked"};
};

export const isCardBookmarked = ({card, root}) => {
  const userId = root.loggedInUser && root.loggedInUser.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  if (!userId || !cardId) return;
  return root.account.$meta.exists("handCards", {userId, cardId});
};

export const getFreeHandSlotsForUser = (targetUserId, root) => {
  if (!targetUserId) return 0;
  const currCount = root.account.$meta.count("queueEntries", {
    userId: targetUserId,
    cardDoneAt: null,
  });
  return Math.max(0, root.account.maxHandSlotCount - currCount);
};

export const canAddHandToQueueErrors = (card, targetUserId, root, {dontCheckFreeSlots} = {}) => {
  if (card.visibility === "deleted") return "Can't add deleted cards";
  if (card.visibility === "archived" && card.status !== "done") {
    return "Can't add non-done archived cards";
  }
  if (hasChildCards(card)) return "Can't add Hero cards";
  if (!dontCheckFreeSlots && getFreeHandSlotsForUser(targetUserId, root) === 0) {
    return "User does not have enough free slots";
  }
  if (targetUserId !== (card.creator && card.creator.id)) {
    if (!card.deck) {
      return "Can't add your private card to team member";
    }
  }
  if (targetUserId !== root.loggedInUser.id && card.deck) {
    const hasAccess = card.deck.project.$meta.exists("access", {userId: targetUserId}, true);
    if (!hasAccess) {
      return "Owner can't access this card";
    }
  }
  if (!userHasPermissionToOwnHand({root, userId: targetUserId})) {
    return "Can't add cards to observers";
  }
  return null;
};

export const canDiscardHandFromQueue = (card) => {
  if (card.deck && card.deck.handSyncEnabled) return false;
  if (card.milestone && card.milestone.handSyncEnabled) return false;
  return true;
};

export const canDiscardHandFromQueueErrors = (card) => {
  if (card.deck && card.deck.handSyncEnabled) return "card is in hand sync deck";
  if (card.milestone && card.milestone.handSyncEnabled) return "card is in hand sync milestone";

  return null;
};
