import {
  useTabs,
  XCol,
  XGrid,
  XText,
  XTextButton,
  springConfigs,
  useReveal,
  xcolors,
} from "@cdx/common";
import uiClasses from "@cdx/common/xui/ui.css";
import {DragController, useDropZone} from "@codecks/dnd";
import range from "lodash/range";
import {useRef, useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {animated} from "react-spring";
import Card, {DragCard, MaybeInaccessibleCard, renderCardPlaceholder} from "../../components/Card";
import {CARD_HEIGHT} from "../../components/Card/card.css";
import Pill from "../../components/Deck/Pill";
import SortableCardList, {useExternalCardDrop} from "../card-panel/SortableCardList";
import {canDiscardHandFromQueueErrors, isCardInHandQueue} from "../hand-queue/hand-queue-utils";
import {getDropErrorGetter, EmptySlot, HandBg} from "../hand-queue/HandQueue";
import {getPathBasedRoutes} from "../../layouts/arena-layout/createArenaContext";
import {api} from "../../lib/api";
import useSet from "../../lib/hooks/useSet";
import {checkIfPresent, useInstance, useRoot} from "../../lib/mate/mate-utils";
import {useModalWithData} from "../../components/Modals";

const getCardIdFromEntry = (entry) =>
  entry.$fakeEntry ? entry.card.id : entry.$meta.get("card", null, {forceRelIds: true});

const getDiscardError = ({card, account}) => {
  const {isLoaded, result} = checkIfPresent(() => {
    if (!isCardInHandQueue({card, account})) return "Not in hand";
    return canDiscardHandFromQueueErrors(card);
  }, api);
  return isLoaded ? result : false;
};

const SingleUserHandQueue = ({
  user,
  root,
  getCardUrl,
  goToCard,
  selectedCardIdSet,
  selectedCards,
  setHiddenCardIds,
  setDroppedCardIds,
  externalDropInfo,
}) => {
  const userId = user.$meta.get("id", null);
  if (!userId) return null;

  const getQueueEntries = (freshRoot) =>
    userId
      ? freshRoot.account.$meta.find("queueEntries", {
          userId,
          $order: "sortIndex",
          cardDoneAt: null,
        })
      : [];

  const queueEntries = getQueueEntries(root);
  const isLoaded = queueEntries.length !== 1 || queueEntries[0].$meta.isLoaded;

  const handleItemDrop = (cardIds, {draggedCardIds}) => {
    const idSet = new Set(draggedCardIds);
    setHiddenCardIds(new Set(idSet));
    setDroppedCardIds((prev) => prev.filter((cardId) => !idSet.has(cardId)));
    return api.mutate.handQueue.setCardOrders({
      cardIds,
      draggedCardIds,
      userId,
    });
  };

  const handleFinishedDrop = () => setHiddenCardIds(new Set());

  return (
    <XCol sp={1} relative>
      <SortableCardList
        isOrderLoaded={isLoaded}
        getDropErrors={getDropErrorGetter(userId, root, queueEntries)}
        getCardIdFromEntry={getCardIdFromEntry}
        entries={queueEntries}
        handleItemDrop={handleItemDrop}
        onFinishedDrop={handleFinishedDrop}
        externalDropInfo={externalDropInfo}
        getFreshItems={() => {
          const freshRoot = api.getRoot();
          getQueueEntries(freshRoot).map((e) => e.card && e.card.$meta.get("cardId"));
          const freshCards = selectedCards.map((c) =>
            api.getModel({modelName: "card", id: c.cardId})
          );
          getCardIdsInHandQueue({root: freshRoot, cards: freshCards});
        }}
        createOutsideEntry={(card) => ({card, $fakeEntry: true})}
        cardContainerKey={`overlay-hand-${userId}`}
        getKey={(entry, idx) =>
          `${userId}-${entry.card ? entry.card.$meta.get("cardId", idx) : idx}`
        }
        renderEntry={({entry, key, ref, ...rest}) => {
          const card = entry.card;
          const discardError = card && getDiscardError({card, account: root.account});
          return (
            <XCol sp={2} align="center" className={uiClasses.hideContainer} key={key} ref={ref}>
              <MaybeInaccessibleCard
                root={root}
                card={card}
                arenaCtx={{type: "handQueue"}}
                dragCtx={
                  !entry.$fakeEntry && {type: "queueEntry", id: entry.id, sourceList: userId}
                }
                hideHandPosition
                getCardUrl={getCardUrl}
                goToCard={goToCard}
                isHighlighted={selectedCardIdSet.has(card && card.id)}
                verticalMargin={24 + 16}
                {...rest}
              />
              {card && !card.$meta.isDeleted() && (
                <XCol className={uiClasses.hideElement}>
                  <XTextButton
                    color="white"
                    disabled={discardError}
                    tooltip={discardError ? `Can't discard: ${discardError}` : null}
                    onClick={() =>
                      api.mutate.handQueue
                        .removeCards({cardIds: [card.id]})
                        .then(() => setDroppedCardIds((prev) => [...prev, card.id]))
                    }
                  >
                    Discard
                  </XTextButton>
                </XCol>
              )}
            </XCol>
          );
        }}
        renderPostfix={(dataIn) =>
          range(dataIn.length, 7, 1).map((idx) => (
            <XCol sp={2}>
              <EmptySlot number={idx + 1} key={idx} type="primary" />
              <div style={{height: 12}} />
            </XCol>
          ))
        }
      />
    </XCol>
  );
};

const getCardIdsInHandQueue = ({root, cards}) =>
  new Set(
    cards.filter((card) => isCardInHandQueue({card, account: root.account})).map((c) => c.id)
  );

const useCardIdsInHandQueue = ({root, cards}) => {
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false);
  const {isLoaded, result: cardIdsInHandQueue} = checkIfPresent(
    () => getCardIdsInHandQueue({root, cards}),
    api
  );
  useEffect(() => {
    if (isLoaded) setHasBeenLoaded(true);
  }, [isLoaded]);
  const selectedCardIdSet =
    isLoaded || hasBeenLoaded ? cardIdsInHandQueue : new Set(cards.map((c) => c.id));
  return {isLoaded: isLoaded || hasBeenLoaded, selectedCardIdSet};
};

const NonHandCardArea = ({
  selectedCardsNotInHand,
  hiddenCardIds,
  root,
  droppedCardIds,
  setDroppedCardIds,
  onDropStart,
  onDropEnd,
}) => {
  const selectedCardIdSet = new Set(selectedCardsNotInHand.map((c) => c.id));
  const droppedCards = droppedCardIds
    .filter((id) => !selectedCardIdSet.has(id))
    .map((id) => api.getModel({modelName: "card", id}));
  const shownCards = [...selectedCardsNotInHand, ...droppedCards].filter(
    (card) => card && !hiddenCardIds.has(card.id)
  );

  const dragItemErrorMessage = (item) => {
    if (!item) return null;
    const {cardInfos} = item.data;
    let error = null;
    cardInfos.some(({getCard}) => {
      error = getDiscardError({card: getCard(), account: root.account});
      return error;
    });
    return error;
  };

  const {
    ref: dropRef,
    isOver,
    dragItem,
  } = useDropZone({
    type: "CARD",
    onDrop: ({item}) => {
      if (dragItemErrorMessage(item)) return;
      const {cardInfos} = item.data;
      const cardIds = cardInfos.map(({cardId}) => cardId);
      onDropStart({cardIds});
      return api.mutate.handQueue
        .removeCards({cardIds})
        .then(() => setDroppedCardIds((prev) => [...prev, ...cardIds]))
        .finally(() => onDropEnd());
    },
  });

  const dragError = dragItemErrorMessage(dragItem);

  const reveal = useReveal(dragError !== "Not in hand" ? dragError : null, {
    config: springConfigs.quick,
  });

  return (
    <XCol sp={3}>
      <XText preset="bold" color="white">
        Cards not in hand
      </XText>
      <XCol pa={3} bg={isOver ? "white_a25" : "white_a10"} rounded="md" relative ref={dropRef}>
        {reveal((props, renderError) => (
          <XCol
            absolute
            inset="full"
            pa={3}
            bg="error600"
            border="error500"
            rounded="md"
            style={{borderWidth: 2, zIndex: 1, opacity: props.value}}
            align="center"
            justify="center"
            as={animated.div}
          >
            <XText color="error100" preset="bold" align="center" size={3}>
              {renderError}
            </XText>
          </XCol>
        ))}
        <XGrid sp={3} style={{minHeight: CARD_HEIGHT}}>
          {shownCards.map((card) => (
            <Card
              key={card.id}
              draggable
              card={card}
              root={root}
              isHighlighted={selectedCardIdSet.has(card.id)}
            />
          ))}
        </XGrid>
      </XCol>
    </XCol>
  );
};

// remembers previously shown users
const useShownUsers = ({users, root}) => {
  const meId = root.loggedInUser?.$meta.get("id", null);
  const prevShownUserIdsRef = useRef([]);
  const alreadyPresentIds = new Set(prevShownUserIdsRef.current);
  const desiredUserMap = new Map(
    [...users.map((u) => [u.$meta.get("id", null), u]), [meId, root.loggedInUser]].filter(([k]) =>
      Boolean(k)
    )
  );
  const shownUserIds = [...new Set([...alreadyPresentIds, ...desiredUserMap.keys()])];
  useEffect(() => {
    prevShownUserIdsRef.current = shownUserIds;
  });
  return shownUserIds.map((id) => desiredUserMap.get(id) || api.getModel({modelName: "user", id}));
};

const LazyHandQueueOverlay = ({cardId, onClose}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  const user = card.assignee || root.loggedInUser;
  return <HandQueueOverlay cards={[card]} users={[user]} root={root} onClose={onClose} />;
};

export const useLazyHandQueueOverlay = (cardId) => {
  const [modalOpen, setModalOpen] = useState(false);
  const renderModal = useModalWithData(modalOpen, {
    onClose: () => setModalOpen(false),
    width: 967,
    hideClose: true,
    purpleBackdrop: true,
  });
  return {
    modal: renderModal(() => (
      <LazyHandQueueOverlay cardId={cardId} onClose={() => setModalOpen(false)} />
    )),
    setModalOpen,
  };
};

export const useHandQueueOverlay = ({root, card, user}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const renderModal = useModalWithData(modalOpen, {
    onClose: () => setModalOpen(false),
    width: 967,
    hideClose: true,
    purpleBackdrop: true,
  });
  return {
    modal: renderModal(() => (
      <HandQueueOverlay
        cards={[card]}
        users={[user]}
        root={root}
        onClose={() => setModalOpen(false)}
      />
    )),
    setModalOpen,
  };
};

const HandQueueOverlay = ({cards, root, users, onClose}) => {
  const history = useHistory();
  const location = useLocation();
  const [hiddenCardIds, {set: setHiddenCardIds}] = useSet();
  const [droppedCardIds, setDroppedCardIds] = useState([]);
  const {getCardUrl, goToCard} = getPathBasedRoutes({
    rootPath: "",
    getRootUrl: () => "",
    history,
  });
  const lastOnCloseRef = useRef(onClose);
  useEffect(() => {
    return () => {
      lastOnCloseRef.current();
    };
  }, [location]);
  useEffect(() => {
    lastOnCloseRef.current = onClose;
  }, [onClose]);

  const {selectedCardIdSet: cardIdsInHandSet, isLoaded} = useCardIdsInHandQueue({cards, root});
  const selectedCardsNotInHand = cards.filter((card) => !cardIdsInHandSet.has(card.id));

  const {externalDropInfo, onDropStart, onDropEnd} = useExternalCardDrop();

  const shownUsers = useShownUsers({users, root});

  const {tabsEl, activeContent} = useTabs(
    shownUsers.map((user) => ({
      key: user.id,
      prefix: {
        sp: 2,
        content: (
          <Pill size="sm" type="count" style={{marginBottom: "0.2rem", marginTop: "0.4rem"}}>
            {root.account.$meta.count("queueEntries", {
              userId: user.$meta.get("id", null),
              cardDoneAt: null,
            })}
          </Pill>
        ),
      },
      label: user.name,
      content: (
        <SingleUserHandQueue
          key={user.id}
          user={user}
          root={root}
          getCardUrl={getCardUrl}
          goToCard={goToCard}
          selectedCardIdSet={cardIdsInHandSet}
          isLoading={!isLoaded}
          selectedCards={cards}
          setHiddenCardIds={setHiddenCardIds}
          setDroppedCardIds={setDroppedCardIds}
          externalDropInfo={externalDropInfo}
        />
      ),
    })),
    shownUsers[0] && shownUsers[0].id,
    {
      onPurple: true,
      title: "Hands",
      borderBg: xcolors.purple400,
    }
  );

  return (
    <DragController
      type="CARD"
      renderItem={({id}) => <DragCard root={root} id={id} />}
      renderPlaceholder={renderCardPlaceholder}
      layerKey="handQueueOverlay"
    >
      <HandBg pa={5} sp={6}>
        <XCol sp={3}>
          {tabsEl}
          {activeContent}
        </XCol>
        <NonHandCardArea
          root={root}
          hiddenCardIds={hiddenCardIds}
          selectedCardsNotInHand={selectedCardsNotInHand}
          droppedCardIds={droppedCardIds}
          setDroppedCardIds={setDroppedCardIds}
          onDropStart={onDropStart}
          onDropEnd={onDropEnd}
        />
      </HandBg>
    </DragController>
  );
};

export default HandQueueOverlay;
