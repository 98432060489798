import {
  useLayoutEffect,
  useRef,
  forwardRef,
  lazy,
  memo,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {useHistory} from "react-router-dom";
import {XCol, XRow, XText, XPush} from "../xui";
import {getStatusForCard} from "../../lib/card-status-utils";
import Ui from "../ui2";
import UnMarkedown from "../Markdown/UnMarkedown";
import {api} from "../../lib/api";
import {useInstance} from "../../lib/mate/mate-utils";
import {getMilestoneState} from "../../features/milestones/milestone-utils";
import Emoji from "../../lib/emoji/EmojiRenderer";
import Uploader, {useUpload} from "../uploader";
import {useUpdatingTime} from "../../features/time-tracking/TimerWidget";
import {getTotalTimeForCard} from "../../features/time-tracking/time-tracking-utils";
import {useCardHoverHandlersWithSelection} from "./useHoverShortcuts";
import {
  rawButtonStyle,
  RevealSpinner,
  cx,
  formatMsAsSec,
  getChromeVersion,
  smoothScrollToNode,
  IconRogue,
  WithTooltip,
  TooltipForChild,
  springConfigs,
} from "@cdx/common";
import styles, {CARD_WIDTH, CARD_HEIGHT} from "./card.css";
import {
  getChildCardInfo,
  getParentCard,
  hasChildCards,
} from "../../features/workflows/workflow-utils";
import {Draggable, useDragStore} from "@codecks/dnd";
import useOriginalCopy from "../../lib/hooks/useOriginalCopy";
import {getCardPosInHandQueue} from "../../features/hand-queue/hand-queue-utils";
import deepEqual from "deep-equal";
import {
  cardSelectionActions,
  getSelectedCardIds,
  useSelectedCardIds,
  useSelectionStore,
  useShiftHoverCards,
} from "../../features/card-selection/useSelectedCards";
import {DisplayFile} from "../../features/card-container/sidebars/attachments";
import {enrichCardProjectTags} from "../Markdown/CardTags";
import {getUpvoteInfo} from "../../features/upvotes/upvote-utils";
import {useCardHighlightStore} from "../../features/card-highlight/useCardHighlight";
import {useHoverTagStore} from "../../features/tag-panel/useHoverTags";
import {CdxCropImg} from "../CdxImg";
import {getDueDateInfo} from "../../features/due-date/due-date-utils";
import {ConversationHint, getConvoPreviewInfo} from "../../features/conversations/ConversationHint";
import {hasPermissionToModifyCard} from "../../lib/permissions";
import {Box, DSIconSelectionEmpty} from "@cdx/ds";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import dsStyles from "@cdx/ds/css/index.css";
import {useMiniCardAnimation} from "./useMiniCardAnimation";
import {animated, useSpring} from "react-spring";
import useFeatureFlag, {FEATURE_FLAGS} from "../useFeatureFlag";
import {useIsInViewport} from "../../lib/hooks/useIsInViewport";
import {mergeRefs} from "react-merge-refs";
import {EXPERIMENTS, experimentStarts, useExperiment} from "../../lib/ab-experiments/experiments";
import {getCardResizeFactor} from "./dragged-card-resize";
import {getSprintState} from "../../features/milestones/sprint-utils";
import {CardBottom} from "./CardBottom";
import {CardBottomHints, ProjectTagTooltip, getBottomHints} from "./CardBottomHints";
import {getBeastLevel} from "./Beast";
import {getIsNewUntil} from "./AddedDuringSprint";
import {getCardOrderInfo} from "../../features/card-panel/ZoneComponents";

const NotifcationHint = () => (
  <WithTooltip
    tooltip="This is a new card"
    as={XCol}
    className={styles.notificationPill}
    align="center"
    justify="center"
  >
    <Ui.Icon.Plus className={styles.bottomHintIcons.sm} />
  </WithTooltip>
);

const TrackingHint = ({runningTracker}) => {
  const getRunningMs = () => {
    if (!runningTracker) return 0;
    const startedAt = runningTracker.$meta.get("startedAt", null);
    const modifyMs = runningTracker.$meta.get("modifyDurationMsBy", 0);
    if (!startedAt) return 0;
    const runningMs =
      (runningTracker.$meta.get("finishedAt", null) || new Date()).getTime() - startedAt.getTime();

    return runningMs + modifyMs;
  };
  const time = getRunningMs();
  useUpdatingTime(time, true);
  return (
    <XRow bg="started600" className={styles.tracker} px={0} align="center">
      <XText color="white" size={1} lineHeight="none" style={{fontVariantNumeric: "tabular-nums"}}>
        {formatMsAsSec(time, {short: true})}
      </XText>
    </XRow>
  );
};

export const MultiDragHint = ({count}) => (
  <XCol
    relative
    className={cx(styles.multiDragHint.base)}
    bg="active200"
    rounded="full"
    border="active500"
    align="center"
    justify="center"
    elevation={2}
  >
    <XText preset="bold" size={1} color="active700">
      {count}
    </XText>
  </XCol>
);

const TopHints = ({convoInfo, isNew, runningTracker, gotoPanel, multiDragCount, cardId}) => {
  return (
    <XRow absolute inset="x" className={styles.topHints} align="center" justify="end" px={0} sp={0}>
      {runningTracker && <TrackingHint runningTracker={runningTracker} />}
      {isNew && <NotifcationHint />}
      {convoInfo && (
        <ConversationHint gotoPanel={gotoPanel} convoInfo={convoInfo} cardId={cardId} />
      )}
      {multiDragCount && <MultiDragHint count={multiDragCount} />}
    </XRow>
  );
};

export const EmojiTag = ({enrichedProjectTags}) => {
  const emojiTags = enrichedProjectTags.map((t) => t.emoji).filter(Boolean);
  if (emojiTags.length === 0) return null;
  return (
    <WithTooltip
      tooltip={<ProjectTagTooltip tags={enrichedProjectTags} />}
      options={{bg: "gray600"}}
      as="span"
    >
      {emojiTags.map((e, i) => (
        <Emoji key={i}>{e}</Emoji>
      ))}{" "}
    </WithTooltip>
  );
};

export const AttachmentTooltip = ({cardId}) => {
  const card = useInstance("card", cardId);
  if (!card) return null;
  return (
    <XCol sp={2} pa={1}>
      {card.attachments.map((att, idx) => (
        <DisplayFile key={att.$meta.get("id", idx)} attachment={att} file={att.file} />
      ))}
    </XCol>
  );
};

const LazyWorkflowHoverInfo = lazy(
  () =>
    import(/* webpackChunkName: "WorkflowOverInfo" */ "../../features/workflows/WorkflowHoverInfo")
);

const ParentCardBadge = ({
  coverType,
  title,
  id,
  goTo,
  parentCardEmojiTags: emojis,
  allowAnimation,
}) => {
  const shownTitle = title && emojis ? `${emojis} ${title}` : title;

  return (
    <WithTooltip
      tooltip={() => <LazyWorkflowHoverInfo card={api.getModel({modelName: "card", id: id})} />}
      options={{placement: "right", bg: "gray800", distanceFromAnchor: 10, delayed: true}}
      as="button"
      className={cx(
        rawButtonStyle,
        styles.parentCardContainer.base,
        coverType &&
          styles.parentCardContainer.byCoverLight[coverType === "white" ? "light" : "dark"],
        allowAnimation && cx(styles.bottom.shinyHero, styles.parentCardContainer.golden)
      )}
      onClick={(e) => {
        if (goTo) {
          e.stopPropagation();
          e.preventDefault();
          goTo();
        }
      }}
    >
      <UnMarkedown onGray>{shownTitle}</UnMarkedown>
    </WithTooltip>
  );
};

// Need to provide custom link a react-router's link will force `history.replace` if the url doesn't
// change but only the location state does. This breaks history back events for codecks though.
const CardLink = ({to, children, ...rest}) => {
  const history = useHistory();
  const handleClick = (e) => {
    if (!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)) {
      history.push(to);
      e.preventDefault();
    }
  };
  return (
    <a href={history.createHref(to)} onClick={handleClick} {...rest}>
      {children}
    </a>
  );
};

const AnimatedCard = ({nodeRef, children}) => {
  const {containerEvents, cardStyles} = useMiniCardAnimation({nodeRef});
  return (
    <div {...containerEvents} style={{perspective: "600px"}}>
      <animated.div style={cardStyles}>{children}</animated.div>
    </div>
  );
};

const MaybeAnimated = ({children, className, allowAnimation, to, nodeRef}) => {
  if (allowAnimation) {
    return (
      <AnimatedCard nodeRef={nodeRef} className={className} to={to}>
        {children}
      </AnimatedCard>
    );
  } else {
    return children;
  }
};

const DumbCard = (props) => {
  const {bag, onClick, ongoingUploads, dragProps, showSelectInterface, dragRef} = props;
  const {
    cardId,
    title,
    status,
    to,
    projectId,
    enrichedProjectTags,
    gotoPanel,
    isSelected,
    isSelecting,
    cardContainerKey,
    hasHoverTag,
    isShown,
    convoInfo,
    isNew,
    hasUnseenChanges,
    coverFileUrl,
    coverType,
    coverFileModifier,
    runningTracker,
    childCardInfo,
    parentCardInfo,
    multiDragCount,
    isHighlighted,
    allowAnimation,
    nodeRef,
  } = bag;

  const isCardHighlighted =
    useCardHighlightStore((s) => s.highlightedCardIds.has(cardId)) || isHighlighted;
  const isShiftHovered = useShiftHoverCards({cardContainerKey, cardId});

  const [lefts, rights] = getBottomHints(bag);
  const hasBottomHints = lefts.length + rights.length > 0;

  // 27 is one pixel off. Should be 26. The bottom has a height of 27, but it overlaps the top part with 1 pixel
  // not changing it back to 26 though, as it will result in all cover images beeing re-requested from image server
  // for little benefit?
  const bottomHeight = childCardInfo ? 38 : 27;

  return (
    <MaybeAnimated allowAnimation={allowAnimation} to={to} nodeRef={nodeRef}>
      <XCol
        className={cx(
          cardStatusVariants[status],
          styles.card.base,
          isSelected && styles.card.isSelected,
          (hasHoverTag || isShiftHovered) && styles.card.isShiftHovered,
          isSelecting && styles.card.isSelecting,
          showSelectInterface && styles.card.isReadyForSelection,
          isShown && styles.card.defaultHighlight,
          isCardHighlighted && styles.card.upvoteHighlight,
          to && dsStyles.cursor.pointer
        )}
        {...(to && {as: CardLink, to})}
      >
        <TopHints
          convoInfo={convoInfo}
          isNew={isNew}
          runningTracker={runningTracker}
          gotoPanel={gotoPanel}
          multiDragCount={multiDragCount}
          cardId={cardId}
        />
        <XCol fillParent minHeight className={styles.card.shadows}>
          <XCol {...dragProps} onClick={onClick} fillParent minHeight ref={dragRef}>
            <XCol
              relative
              fillParent
              className={cx(styles.top.base, hasUnseenChanges && styles.top.withChanges)}
              bg={!coverType || coverType === "white" ? "white" : "gray900"}
              minHeight
            >
              {coverFileUrl && (
                <Box absolute inset={0} styleChild>
                  <CdxCropImg
                    src={coverFileUrl}
                    width={CARD_WIDTH}
                    height={CARD_HEIGHT - bottomHeight}
                    imgClassName={dsStyles.height["100%"]}
                    offset={
                      coverFileModifier
                        ? [coverFileModifier.offsetX, coverFileModifier.offsetY]
                        : null
                    }
                  />
                </Box>
              )}
              {!coverFileModifier?.noBlur && hasBottomHints && coverFileUrl && (
                <CdxCropImg
                  src={coverFileUrl}
                  width={CARD_WIDTH}
                  height={CARD_HEIGHT - bottomHeight}
                  className={styles.coverImgBottomLayer.outer}
                  imgClassName={styles.coverImgBottomLayer.img}
                  offset={
                    coverFileModifier
                      ? [coverFileModifier.offsetX, coverFileModifier.offsetY]
                      : null
                  }
                />
              )}
              <XCol
                absolute
                inset="full"
                className={cx(
                  styles.border.base,
                  coverType && styles.border.byCoverType[coverType]
                )}
              />
              <RevealSpinner size={40} show={title === null} withCover />
              <XCol
                px={1}
                pt={2}
                minHeight
                relative
                className={cx(
                  coverType && styles.upperContent.withCover,
                  coverType && styles.upperContent.byCoverType[coverType]
                )}
              >
                {parentCardInfo && (
                  <ParentCardBadge
                    coverType={coverType}
                    {...parentCardInfo}
                    allowAnimation={allowAnimation}
                  />
                )}
                <div
                  className={cx(
                    styles.cardText.base,
                    childCardInfo
                      ? styles.cardText.isParent
                      : parentCardInfo
                        ? styles.cardText.isChild
                        : styles.cardText.default
                  )}
                  dir="auto"
                >
                  <EmojiTag enrichedProjectTags={enrichedProjectTags} />
                  {title && (
                    <UnMarkedown
                      maxChars={120}
                      projectId={projectId}
                      onDark={coverType && coverType !== "white"}
                      className={cx(
                        coverType ? styles.cardTextInner.withCover : styles.cardTextInner.noCover,
                        coverType && styles.cardTextInner.byCoverType[coverType]
                      )}
                      as="span"
                    >
                      {title}
                    </UnMarkedown>
                  )}
                </div>
              </XCol>
              <XPush />
              {ongoingUploads && (
                <XCol px={1} pb={0}>
                  <Uploader.Uploads ongoingUploads={ongoingUploads} noLabel onDark={coverFileUrl} />
                </XCol>
              )}
              {hasBottomHints && (
                <CardBottomHints
                  withCover={coverFileUrl}
                  coverType={coverType}
                  lefts={lefts}
                  rights={rights}
                />
              )}
            </XCol>
            <CardBottom bag={bag} />
          </XCol>
        </XCol>
      </XCol>
    </MaybeAnimated>
  );
};

export const CheckMark = ({bag, handleSelectionClick, showSelectInterface}) => {
  const {isSelected, isSelecting} = bag;
  return (
    <TooltipForChild tooltip="Select card" delayed>
      <div
        className={cx(
          styles.selectCheck.container.default,
          isSelecting && styles.selectCheck.container.isSelecting,
          !isSelected && showSelectInterface && styles.selectCheck.container.isReady
        )}
        onClick={handleSelectionClick}
        data-cdx-clickable
      >
        <DSIconSelectionEmpty size={24} />
      </div>
    </TooltipForChild>
  );
};

const SelectableCard = memo(({bag, dragProps, dragRef}) => {
  const {isSelecting, isSelected, cardId, canModify, meId, hasHoverTag, cardContainerKey, nodeRef} =
    bag;
  const {waitedForSelection, events} = useCardHoverHandlersWithSelection({
    type: "MINI_CARD",
    isSelecting,
    cardId,
    cardNodeRef: nodeRef,
    cardContainerKey,
  });
  const showSelectInterface =
    process.env.REACT_APP_MODE !== "open" && (isSelecting || waitedForSelection || hasHoverTag);

  const handleSelectionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSelected) {
      cardSelectionActions.removeSelectedCardIds([cardId], cardContainerKey);
    } else {
      cardSelectionActions.addSelectedCardIds([cardId], cardContainerKey);
    }
  };

  const handleClick = (e) => {
    if (isSelecting) {
      handleSelectionClick(e);
    }
  };

  const {dropAreaProps, ongoingUploads} = useUpload({
    id: `attachment-${cardId}`,
    multiple: true,
    affectsQuota: true,
    onUpload: (fileData) => api.mutate.cards.addFile({cardId, fileData, userId: meId}),
  });

  const chromeVersion = getChromeVersion();

  return (
    <div
      className={cx(
        styles.backdrop.default,
        isSelected && styles.backdrop.isSelected,
        !(chromeVersion && chromeVersion >= 86) && styles.backdrop.nonChrome86
      )}
      {...events}
    >
      {canModify && <Uploader.DropArea label="Add file(s) as attachment" {...dropAreaProps} />}
      <CheckMark
        bag={bag}
        handleSelectionClick={handleSelectionClick}
        showSelectInterface={showSelectInterface}
      />
      <DumbCard
        bag={bag}
        onClick={handleClick}
        ongoingUploads={ongoingUploads}
        dragProps={dragProps}
        showSelectInterface={showSelectInterface}
        dragRef={dragRef}
      />
    </div>
  );
});

const MakeDraggable = ({comp: CardComp, bag}) => {
  const {isSelected, cardId, canModify, isCardLoaded, arenaCtxType, dragCtx} = bag;
  const getData = () => {
    const selectedCardIds = getSelectedCardIds();
    const cardIds = isSelected ? [...selectedCardIds] : [cardId];
    const item = {
      cardInfos: cardIds.map((id) => ({
        cardId: id,
        getCard: () => api.getModel({modelName: "card", id}),
      })),
      dragFrom: arenaCtxType,
      dragCtx,
    };
    return item;
  };
  return (
    <Draggable
      type="CARD"
      id={cardId}
      itemData={getData}
      disabled={!canModify || !isCardLoaded || process.env.REACT_APP_MODE === "open"}
    >
      {({handlers: dragHandlers, ref: dragRef}) => (
        <CardComp dragProps={dragHandlers} dragRef={dragRef} bag={bag} />
      )}
    </Draggable>
  );
};

const agesAgo = new Date(0);

const fileEqual = (f1, f2) => {
  if (f1 === f2) return true;
  if (f1 === null || f2 === null) return false;
  return f1.url === f2.url;
};

export const usersEqual = (u1, u2) => {
  if (u1 === u2) return true;
  if (!u1 || !u2) return false;
  return u1.id === u2.id && u1.name === u2.name && fileEqual(u1.profileImage, u2.profileImage);
};

export const MaybeInaccessibleCard = forwardRef((props, ref) => {
  const {card} = props;
  if (!card || card.$meta.isDeleted()) {
    return (
      <XCol
        align="center"
        justify="center"
        relative
        ref={ref}
        className={styles.inaccessible}
        sp={0}
        px={2}
      >
        <IconRogue
          color="gray500"
          strokeColor="gray900"
          size="xxl"
          className={styles.inaccessibleIcon}
        />
        <XText color="gray400" size={2} align="center" className={styles.inaccessibleText}>
          Inaccessible card
        </XText>
      </XCol>
    );
  } else {
    return <OuterCard ref={ref} {...props} />;
  }
});

const getCoverType = (meta, cardMeta) => {
  const explicitVal = cardMeta?.coverFileModifier?.mode;
  if (explicitVal) return explicitVal === "dark" ? "gray" : "white";
  const h = meta && meta.luminanceHisto;
  const s = meta && meta.sharpness;
  if (!h) return "gray";
  const lightAreaScore = h[9] + h[8] * 0.15 + h[7] * 0.05;
  if (lightAreaScore > 300) return "white";
  if (s[0] > 70) return "gray";
  return lightAreaScore < 20 ? "dark" : "gray";
};

const Card = (props) => {
  const {
    root,
    notifications,
    card,
    getCardUrl,
    // goToCard, used within propsRef
    activeCardId,
    allowSelection,
    onMouseEnter,
    onMouseLeave,
    arenaCtx,
    multiDragCount,
    dragCtx: rawDragCtx,
    hideHandPosition,
    cardContainerKey,
    isLeader,
    draggable,
    isDroppedExternal,
    isHighlighted,
    nodeRef,
    shownManualOrder,
  } = props;

  const propsRef = useRef(props);
  propsRef.current = props;

  const canModify =
    process.env.REACT_APP_MODE !== "open" && hasPermissionToModifyCard({root, card});
  const status = getStatusForCard(card);
  const cardId = card.$meta.get("cardId", null) ?? null;
  const resNotis = notifications && notifications.perCardResolvables[cardId];
  const cardDiffs = notifications && notifications.perCardDiffs[cardId];
  const hasUnseenChanges = cardDiffs || (resNotis && resNotis.some((rn) => !rn.isParticipating));
  const isNew = cardDiffs && cardDiffs.changes.isNew;
  const to =
    getCardUrl && getCardUrl({card, panel: hasUnseenChanges ? "history" : null, cardContainerKey});
  const {pathname, state} = to || {};
  const isPrivate = !card.deck;
  const arenaCtxType = arenaCtx && arenaCtx.type;
  let handPosition = null;
  const me = root.loggedInUser;
  const meId = me?.$meta.get("id", null);
  if (!hideHandPosition) {
    handPosition = getCardPosInHandQueue({card, account: root.account});
  }

  const attachmentCount = card.$meta.count("attachments");
  let milestone = null;
  const withinDeck = arenaCtxType === "deck" && arenaCtx.model;
  if (card.milestone && arenaCtxType !== "milestone") {
    if (!withinDeck || withinDeck.milestone !== card.milestone) {
      milestone = card.milestone;
    }
  }

  const milestoneId = milestone && milestone.id;
  const milestoneColor = milestone && milestone.$meta.get("color", null);
  const milestoneState = milestone && getMilestoneState(root.account, milestone);

  let sprint = null;
  if (card.sprint && arenaCtxType !== "sprint") sprint = card.sprint;

  const sprintId = sprint?.$meta.get("id", null) ?? null;
  const sprintColor = sprint?.sprintConfig.$meta.get("color", null) ?? null;
  let sprintState = sprint && getSprintState(sprint);
  const project = card.deck && card.deck.project;
  const projectId = project && project.id;
  const markerColor = (!withinDeck && project?.$meta.get("markerColor", null)) || null;
  const freshEnrichedProjectTags = enrichCardProjectTags(card);
  const enrichedProjectTags = useOriginalCopy(freshEnrichedProjectTags, deepEqual);
  const dragCtx = useOriginalCopy(rawDragCtx);

  const cardTitle = card.$meta.get("title", null);
  const freshAssignee = card.assignee;
  const assignee = useOriginalCopy(freshAssignee, usersEqual);
  const isCardLoaded = card.$meta.isLoaded;
  const isArchived = card.visibility === "archived";

  const lastEntry = card.$meta.first("resolvableEntries", {$order: "-lastChangedAt"});
  const lastEntryChanged = lastEntry && lastEntry.$meta.get("lastChangedAt", agesAgo);
  const lastUpdatedAt = card.$meta.get("lastUpdatedAt", agesAgo);
  const lastUpdateTime = lastEntryChanged > lastUpdatedAt ? lastEntryChanged : lastUpdatedAt;

  const parentCard = getParentCard(card);

  const parentCardTitle = parentCard && parentCard.title;
  const parentCardId = parentCard && parentCard.id;
  const parentCardIsLoaded = parentCard && parentCard.$meta.isLoaded;
  const parentCardEmojiTags =
    parentCard &&
    enrichCardProjectTags(parentCard)
      .map((t) => t.emoji)
      .filter(Boolean)
      .join("");

  const gotoPanel = useCallback(
    (targetPanel) => propsRef.current.goToCard?.({cardId, targetPanel}),
    [cardId]
  );
  const goToParentCard = useCallback(
    () => propsRef.current.goToCard?.({cardId: parentCardId}),
    [parentCardId]
  );

  const getCoverInfo = () => {
    if (card.coverFile) {
      return {coverFile: card.coverFile, coverFileCardMeta: card.meta};
    }
    if (root.account.allowInheritHeroCover && parentCard && parentCard.coverFile) {
      return {
        coverFile: parentCard.coverFile,
        coverFileCardMeta: parentCard.meta,
      };
    }
    return {coverFile: null, coverFileCardMeta: null};
  };

  const {coverFile, coverFileCardMeta} = getCoverInfo();
  const coverFileUrl = coverFile && coverFile.$meta.get("url", null);
  const coverFileMeta = coverFile && coverFile.$meta.get("meta", null);
  const coverType = coverFileMeta && getCoverType(coverFileMeta, coverFileCardMeta);
  const coverFileModifier = useOriginalCopy(coverFileCardMeta?.coverFileModifier ?? null);

  const convoInfo = useOriginalCopy(getConvoPreviewInfo({notifications, card, root}), deepEqual);
  const att = process.env.REACT_APP_MODE !== "open" && me.activeTimeTracker;
  let runningTracker = null;
  if (att && att.card && att.card.id === cardId && me.$meta.isLoaded) {
    runningTracker = card.$meta.first("timeTrackingSegments", {
      userId: me.id,
      finishedAt: null,
      $order: "createdAt",
    });
  }

  const totalTrackingTime =
    process.env.REACT_APP_MODE !== "open" &&
    root.account.timeTrackingMode !== "none" &&
    getTotalTimeForCard(card);

  const childCardInfo = useOriginalCopy(
    hasChildCards(card) && getChildCardInfo(card, root.account),
    deepEqual
  );
  const dueDateInfo = useOriginalCopy(getDueDateInfo({card, account: root.account}));
  const depInfo = useOriginalCopy(
    card.hasBlockingDeps
      ? {hasBlockingDeps: true, dependenciesEnabled: root.account.dependenciesEnabled}
      : null
  );

  const upvoteInfo = useOriginalCopy(getUpvoteInfo({card, root}), deepEqual);
  const hasHoverTag = useHoverTagStore((s) => s.cardIdSet.has(cardId));
  const {isSelecting, isSelected} = useSelectionStore((s) => ({
    isSelecting: allowSelection && s.selectedCardIds.size > 0,
    isSelected: allowSelection && s.selectedCardIds.has(cardId),
  }));

  const isShown = Boolean(activeCardId && activeCardId === cardId);
  const wordCount = card.isDoc ? card.meta.content?.wordCount || 0 : null;
  const allowAnimationFF = useFeatureFlag(root.account, FEATURE_FLAGS.animatedCards);
  const withProjectEffortIcon = useFeatureFlag(root.account, FEATURE_FLAGS.projectEffortIcon);
  const isNewOrg = root.account.$meta.get("createdAt", new Date(0)) > experimentStarts.cardHover;
  const experimentActive = useExperiment(EXPERIMENTS.CardHoverAnimation, root.account);
  const canShowAnimation = allowAnimationFF || (experimentActive && isNewOrg);
  const allowAnimation = canShowAnimation && (!me || !me.disableAnimations);
  const beastLevel = getBeastLevel(root.account, card);
  const isNewUntil = getIsNewUntil(root.account, card);

  const effortIcon = (withProjectEffortIcon ? card.deck?.project.effortIcon : null) ?? null;

  const zoneInfo = useOriginalCopy(getCardOrderInfo(card, root.account));

  const bag = useMemo(
    () => ({
      title: cardTitle,
      status,
      effort: card.effort,
      priority: card.priority,
      to: pathname && {pathname, state},
      assignee,
      projectId,
      enrichedProjectTags,
      isPrivate,
      handPosition,
      cardId,
      gotoPanel,
      attachmentCount,
      milestoneId,
      milestoneColor,
      milestoneState,
      sprintId,
      sprintColor,
      sprintState,
      isSelecting,
      isSelected,
      isShown,
      onMouseEnter,
      onMouseLeave,
      canModify,
      meId,
      hasHoverTag,
      isCardLoaded,
      arenaCtxType,
      checkboxStats: card.checkboxStats,
      isArchived,
      convoInfo,
      lastUpdateTime,
      isNew,
      hasUnseenChanges,
      coverFileUrl,
      coverType,
      coverFileModifier,
      runningTracker,
      totalTrackingTime,
      childCardInfo,
      parentCardInfo:
        parentCardIsLoaded && parentCardId
          ? {id: parentCardId, title: parentCardTitle, goTo: goToParentCard, parentCardEmojiTags}
          : null,
      multiDragCount,
      dragCtx,
      isLeader,
      cardContainerKey,
      depInfo,
      upvoteInfo,
      isHighlighted,
      dueDateInfo,
      wordCount,
      markerColor,
      isDoc: card.isDoc,
      allowAnimation,
      nodeRef,
      effortIcon,
      beastLevel,
      isNewUntil,
      zoneInfo,
      shownManualOrder,
    }),
    [
      cardTitle,
      status,
      card.effort,
      card.priority,
      pathname,
      state,
      assignee,
      projectId,
      enrichedProjectTags,
      isPrivate,
      handPosition,
      cardId,
      gotoPanel,
      attachmentCount,
      milestoneId,
      milestoneColor,
      milestoneState,
      sprintId,
      sprintColor,
      sprintState,
      isSelecting,
      isSelected,
      isShown,
      onMouseEnter,
      onMouseLeave,
      canModify,
      meId,
      hasHoverTag,
      isCardLoaded,
      arenaCtxType,
      card.checkboxStats,
      isArchived,
      convoInfo,
      lastUpdateTime,
      isNew,
      hasUnseenChanges,
      coverFileUrl,
      coverType,
      coverFileModifier,
      runningTracker,
      totalTrackingTime,
      childCardInfo,
      parentCardTitle,
      parentCardId,
      goToParentCard,
      parentCardIsLoaded,
      parentCardEmojiTags,
      multiDragCount,
      dragCtx,
      isLeader,
      cardContainerKey,
      depInfo,
      upvoteInfo,
      isHighlighted,
      dueDateInfo,
      wordCount,
      markerColor,
      card.isDoc,
      allowAnimation,
      nodeRef,
      effortIcon,
      beastLevel,
      isNewUntil,
      zoneInfo,
      shownManualOrder,
    ]
  );

  if (isDroppedExternal) return renderCardPlaceholder({ref: null});

  const CardComp = allowSelection ? SelectableCard : DumbCard;
  if (draggable) {
    return <MakeDraggable comp={CardComp} bag={bag} />;
  } else {
    return <CardComp bag={bag} />;
  }
};

/** @type any */
const OuterCard = forwardRef((props, ref) => {
  const [viewPortRef, isVisible] = useIsInViewport();
  const nodeRef = useRef(null);
  const mergedRefs = useMemo(
    () => mergeRefs([ref, nodeRef, viewPortRef]),
    [ref, nodeRef, viewPortRef]
  );

  useLayoutEffect(() => {
    if (props.isLeader && nodeRef.current) {
      smoothScrollToNode(nodeRef.current, {onlyIfInvisible: true, offset: 60});
    }
  }, [props.isLeader, nodeRef]);

  return (
    <div ref={mergedRefs} className={cx(styles.outer.base, !isVisible && styles.outer.invisible)}>
      {isVisible ? (
        <Card {...props} nodeRef={nodeRef} />
      ) : (
        props.card?.$meta.get("title", null) ?? null
      )}
    </div>
  );
});

export default OuterCard;

export const renderCardPlaceholder = ({ref}) => (
  <Box
    width="cardWidth"
    height="cardHeight"
    borderColor="light"
    rounded="card"
    borderWidth={1}
    ref={ref}
  />
);

const clamp = (val, min, max) => {
  return Math.min(max, Math.max(min, val));
};

const AnimatedDragCard = (props) => {
  const [cardStyles, springApi] = useSpring(() => ({
    rotateX: 0,
    rotateY: 0,
    scale: 1,
    config: springConfigs.quick,
  }));

  useEffect(() => {
    let lastPos = null;
    let initialInfo = null;
    return useDragStore.subscribe(
      (state) => state.dragInfo,
      (dragInfo) => {
        if (dragInfo) {
          if (lastPos) {
            if (!initialInfo) {
              initialInfo = {
                dimensions: {...dragInfo.dimensions},
                mouseOffset: {...dragInfo.mouseOffset},
              };
            }
            const dx = lastPos.x - dragInfo.currentPos.x;
            const dy = lastPos.y - dragInfo.currentPos.y;
            const x = dragInfo.currentPos.x - dragInfo.mouseOffset.x;
            const y = dragInfo.currentPos.y - dragInfo.mouseOffset.y;
            const scale = getCardResizeFactor({x, y});

            springApi.start({
              rotateX: clamp(dy * 2, -30, 30),
              rotateY: -clamp(dx * 2, -30, 30),
              // translateX: initialInfo.mouseOffset.x * (scale - 1),
              // translateY: initialInfo.mouseOffset.y * (scale - 1),
              scale,
            });

            dragInfo.dimensions = {
              width: scale * initialInfo.dimensions.width,
              height: scale * initialInfo.dimensions.height,
            };
            dragInfo.mouseOffset = {
              x: scale * initialInfo.mouseOffset.x,
              y: scale * initialInfo.mouseOffset.y,
            };
          }
          lastPos = {x: dragInfo.currentPos.x, y: dragInfo.currentPos.y};
        }
      }
    );
  }, [springApi]);
  return (
    <div style={{perspective: "600px"}}>
      <animated.div style={cardStyles}>
        <Card {...props} />
      </animated.div>
    </div>
  );
};

export const DragCard = ({id, root}) => {
  const card = useInstance("card", id);
  const selectedCardIds = useSelectedCardIds();

  if (!card) return null;
  return (
    <AnimatedDragCard
      card={card}
      root={root}
      multiDragCount={selectedCardIds.size > 1 && selectedCardIds.size}
    />
  );
};
