import {
  Arrow,
  XCol,
  XPlainButton,
  XPush,
  XRow,
  XText,
  XTextButton,
  useEsc,
  xcolors,
} from "@cdx/common";
import Markdown from "../../components/Markdown";
import dsStyles from "@cdx/ds/css/index.css";

export const PreviewCardContent = ({
  contentAsText,
  togglePreview,
  handleSubmit,
  buttonLabel,
  project,
}) => {
  useEsc(togglePreview);
  return (
    <XCol fillParent minHeight pb={1}>
      <XCol px={1} py={1} bg="active" relative noShrink className={dsStyles.roundedTop.card}>
        <Arrow pointTo="bottom" color={xcolors.active} size="md" />
        <XText size={1} color="white" align="center">
          Previewing content
        </XText>
      </XCol>
      <XCol sp={1} fillParent minHeight relative>
        <XCol scrollable fillParent onClick={togglePreview} relative>
          <Markdown
            projectId={project && project.id}
            size="lg"
            style={{padding: "1rem", flex: "auto"}}
            parseCheckboxes
            autoTitle
          >
            {contentAsText}
          </Markdown>
        </XCol>
        <XRow sp={2} align="center" px={1} pl={2}>
          <XTextButton color="red" active onClick={togglePreview}>
            Preview
          </XTextButton>
          <XPush />
          <XPlainButton color="red" onClick={() => handleSubmit()}>
            {buttonLabel}
          </XPlainButton>
        </XRow>
      </XCol>
    </XCol>
  );
};
