import {cx, TooltipForChild, useToggle, XCol, XPush, XRow} from "@cdx/common";
import {useDragItem} from "@codecks/dnd";
import range from "lodash/range";
import {useEffect, forwardRef} from "react";
import {MaybeInaccessibleCard} from "../../components/Card";
import {CARD_WIDTH} from "../../components/Card/card.css";
import {api} from "../../lib/api";
import SortableCardList from "../card-panel/SortableCardList";
import {handQueueStyles as styles} from "./hand-queue.css";
import uiClasses from "@cdx/common/xui/ui.css";
import {canAddHandToQueueErrors, getFreeHandSlotsForUser} from "./hand-queue-utils";
import {
  EffortAndTimeTrackingSummary,
  SelectAllIcon,
  useHoverWaiter,
} from "../card-panel/SwimlaneLabel";
import {cardSelectionActions} from "../card-selection/useSelectedCards";
import {useCardContainer} from "../card-selection/useCardContainer";
import {checkIfPresent} from "../../lib/mate/mate-utils";
import {ReactComponent as CornerGoldTlSvg} from "./imgs/corner-gold-tl.svg";
import {ReactComponent as CornerGoldTrSvg} from "./imgs/corner-gold-tr.svg";
import {ReactComponent as CornerGoldBlSvg} from "./imgs/corner-gold-bl.svg";
import {ReactComponent as CornerGoldBrSvg} from "./imgs/corner-gold-br.svg";
import {ReactComponent as CornerSilverTlSvg} from "./imgs/corner-silver-tl.svg";
import {ReactComponent as CornerSilverTrSvg} from "./imgs/corner-silver-tr.svg";
import {ReactComponent as CornerSilverBlSvg} from "./imgs/corner-silver-bl.svg";
import {ReactComponent as CornerSilverBrSvg} from "./imgs/corner-silver-br.svg";
import ShowAllCardsButton from "./ShowAllCardsButton";
import DonePile, {DonePileTile} from "./DonePile";
import {HelperDropDown} from "../../components/Helper";
import {RawMarkdownImg} from "../../components/Markdown/InnerMarkdown";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";
import {DSIconButton, DSIconHistory} from "@cdx/ds";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import {useCardNavigation} from "../card-panel/mini-card-navigation-shortcuts";

const cornerByType = {
  primary: {
    Tl: CornerGoldTlSvg,
    Tr: CornerGoldTrSvg,
    Bl: CornerGoldBlSvg,
    Br: CornerGoldBrSvg,
  },
  secondary: {
    Tl: CornerSilverTlSvg,
    Tr: CornerSilverTrSvg,
    Bl: CornerSilverBlSvg,
    Br: CornerSilverBrSvg,
  },
};

export const HandBg = ({children, type = "primary", ...props}) => {
  const Corner = cornerByType[type];

  return (
    <XCol
      rounded="md"
      relative
      className={cx(styles.handBg.base, styles.handBg.byType[type], colorThemes.purple350)}
      elevation={1}
    >
      <Corner.Tl className={cx(styles.corner.base, styles.corner.topLeft)} />
      <Corner.Tr className={cx(styles.corner.base, styles.corner.topRight)} />
      <Corner.Bl className={cx(styles.corner.base, styles.corner.bottomLeft)} />
      <Corner.Br className={cx(styles.corner.base, styles.corner.bottomRight)} />
      <XCol {...props}>{children}</XCol>
    </XCol>
  );
};

export const EmptySlot = forwardRef(({number, type}, ref) => (
  <XCol
    align="center"
    justify="center"
    className={cx(styles.emptySlot.base, styles.emptySlot.byType[type])}
    ref={ref}
  >
    {number}
  </XCol>
));

const getQueueEntries = (freshRoot, userId) =>
  freshRoot.account.$meta.find("queueEntries", {
    userId,
    $order: "sortIndex",
    cardDoneAt: null,
  });
const getCardIdFromEntry = (entry) =>
  entry.$fakeEntry ? entry.card.id : entry.$meta.get("card", null, {forceRelIds: true});

export const getDropErrorGetter = (userId, root, entries) => {
  const getDropErrors = (item) => {
    const {isLoaded, result} = checkIfPresent(() => {
      const existingCardIds = new Set(entries.map(getCardIdFromEntry));
      const {dragFrom, dragCtx, cardInfos} = item.data;
      // drag and drop from within same list is always possible
      if (dragFrom === "handQueue" && dragCtx && dragCtx.sourceList === userId) {
        return null;
      }
      if (cardInfos.every(({cardId}) => existingCardIds.has(cardId))) {
        return null;
      }
      const freeSlots = getFreeHandSlotsForUser(userId, root);
      if (freeSlots < cardInfos.length) {
        return "No more free slots";
      }
      const errs = cardInfos.map(({getCard}) => {
        const card = getCard();
        if (card.status === "done") return "Can't add completed cards to hand";
        const err = canAddHandToQueueErrors(card, userId, root, {dontCheckFreeSlots: true});
        if (err) return err;
        return false;
      });
      return errs.find(Boolean);
    }, api);
    return isLoaded ? result : null;
  };
  return getDropErrors;
};

const CardList = ({
  userId,
  root,
  notifications,
  getCardUrl,
  goToCard,
  activeCard,
  arenaCtx,
  location,
  queueEntries,
  showAll,
  setShowAllOff,
  setShowAllOn,
  idx: sortIdx,
  type,
}) => {
  const totalCount = queueEntries.length;

  const cardContainerKey = `u:${userId}-hand`;

  const isLoaded = queueEntries.length !== 1 || queueEntries[0].$meta.isLoaded;

  useEffect(() => {
    if (showAll && totalCount <= 7 && isLoaded) setShowAllOff();
  }, [showAll, setShowAllOff, totalCount, isLoaded]);

  const handleItemDrop = (cardIds, {draggedCardIds}) =>
    api.mutate.handQueue.setCardOrders({cardIds, draggedCardIds, userId});

  const shownEntries = showAll ? queueEntries : queueEntries.slice(0, 7);

  useCardContainer({
    cardContainerKey,
    visibleCardIds: shownEntries.map(getCardIdFromEntry),
    allCardsInfo: showAll
      ? null
      : {
          cardIds: queueEntries.map(getCardIdFromEntry),
          onShow: setShowAllOn,
        },
    idx: sortIdx,
  });

  const cursor = useCardNavigation({
    activeCard,
    getCardIdsForGroup: () => shownEntries.map(getCardIdFromEntry),
    groups: [cardContainerKey],
    goToCard,
    category: arenaCtx.shortcutCategory,
    location,
  });

  return (
    <SortableCardList
      isOrderLoaded={isLoaded}
      getDropErrors={getDropErrorGetter(userId, root, queueEntries)}
      allowSelection
      getCardIdFromEntry={getCardIdFromEntry}
      entries={shownEntries}
      handleItemDrop={handleItemDrop}
      getFreshItems={() => getQueueEntries(api.getRoot())}
      createOutsideEntry={(card) => ({card, $fakeEntry: true})}
      cardContainerKey={cardContainerKey}
      getKey={(entry, idx) =>
        `${userId}-${
          entry.$fakeEntry
            ? `card-${entry.card.$meta.get("cardId", idx)}`
            : entry.$meta.get("id", `idx-${idx}`)
        }`
      }
      renderEntry={({entry, key, dragCtx, ...rest}) => {
        const card = entry.card;
        const cardId = getCardIdFromEntry(entry);
        return (
          <MaybeInaccessibleCard
            root={root}
            notifications={notifications}
            key={key}
            card={card}
            getCardUrl={getCardUrl}
            goToCard={goToCard}
            isLeader={
              cursor && cursor.cardId === cardId && cursor.cardContainerKey === cardContainerKey
            }
            activeCardId={activeCard && activeCard.id}
            arenaCtx={arenaCtx}
            dragCtx={{
              ...dragCtx,
              ...(!entry.$fakeEntry && {type: "queueEntry", id: entry.id, sourceList: userId}),
            }}
            hideHandPosition
            {...rest}
          />
        );
      }}
      renderPostfix={(keysAndDataIn) => (
        <>
          {(showAll || totalCount > 7) && (
            <XCol justify="center">
              <ShowAllCardsButton
                isCollapsed={!showAll}
                onClick={showAll ? setShowAllOff : setShowAllOn}
                totalCount={totalCount}
                onPurple={type === "primary"}
              />
            </XCol>
          )}
          {range(keysAndDataIn.length, 7, 1).map((idx) => (
            <EmptySlot number={idx + 1} key={idx} type={type} />
          ))}
        </>
      )}
    />
  );
};

const RealHandQueue = ({
  userId,
  arenaCtx,
  root,
  notifications,
  activeCard,
  getLabel,
  goToCard,
  getCardUrl,
  location,
  onShowDoneCards,
  idx,
  type = "primary",
  showStreak,
}) => {
  const [showAll, {on: setShowAllOn, off: setShowAllOff}] = useToggle();
  const cardContainerKey = `u:${userId}-hand`;

  const handleSelectAll = () => {
    setShowAllOn();
    cardSelectionActions.addSelectedCardIds(
      cards.map((c) => c.id),
      cardContainerKey
    );
  };

  const handleDeselectAll = () => {
    cardSelectionActions.removeSelectedCardIds(cards.map((c) => c.id));
  };

  const queueEntries = getQueueEntries(root, userId);
  const cards = queueEntries.map((e) => e.card).filter((c) => c && !c.$meta.isDeleted());
  const {handlers, waited} = useHoverWaiter();
  const dragItem = useDragItem("CARD");
  return (
    <HandBg type={type} px={3} py={2} sp={1} className={uiClasses.hideContainer} {...handlers}>
      <XRow align="center" sp={2}>
        <XRow>
          <div style={{minWidth: CARD_WIDTH + 16, paddingRight: 8}}>{getLabel({waited})}</div>
          <XRow sp={2} align="center">
            <EffortAndTimeTrackingSummary
              cards={cards}
              onHand
              style={{position: "relative", top: 1}}
              root={root}
            />
            <SelectAllIcon
              cards={cards}
              waitedForSelection={waited}
              onSelectAll={handleSelectAll}
              onDeselectAll={handleDeselectAll}
              isDragging={dragItem}
            />
          </XRow>
        </XRow>
        <XPush />
        <XRow align="center" sp={1}>
          {type === "primary" ? (
            <HelperDropDown maxWidth={275} initialOpenKey="handV2.initialInfo" hide={!waited}>
              <p>
                The Hand is your personal queue for upcoming work and can contain cards from any
                project, deck or milestone.
              </p>
              <p>Watch this video for a quick introduction</p>
              <a
                href="https://www.youtube.com/watch?v=Raovt6o5fpI"
                target="_blank"
                rel="noopener noreferrer"
                style={{display: "block"}}
              >
                <RawMarkdownImg
                  alt="preview of youtube video"
                  src="https://uploads.codecks.io/account-14c3021c-95ad-11e9-b939-5368e19a8f5e/2021/9RAlIbJTmH/group-1-1.png"
                  imgDims={[275, 350]}
                  width="480"
                  height="360"
                />
              </a>
            </HelperDropDown>
          ) : (
            <TooltipForChild tooltip="User activity">
              <DSIconButton
                icon={<DSIconHistory />}
                variant="tertiary"
                size="sm"
                negatePadding
                className={uiClasses.hideElement}
                to={{pathname: `${arenaCtx.routes.getRootUrl()}/activity`, state: {userId}}}
              />
            </TooltipForChild>
          )}
        </XRow>
      </XRow>
      <XRow sp={3}>
        <DonePileTile
          userId={userId}
          root={root}
          onShow={onShowDoneCards}
          showStreak={showStreak}
        />
        <CardList
          userId={userId}
          root={root}
          notifications={notifications}
          getCardUrl={getCardUrl}
          goToCard={goToCard}
          activeCard={activeCard}
          arenaCtx={arenaCtx}
          location={location}
          queueEntries={queueEntries}
          waitedForSelection={waited}
          showAll={showAll}
          setShowAllOn={setShowAllOn}
          setShowAllOff={setShowAllOff}
          idx={idx}
          type={type}
        />
      </XRow>
    </HandBg>
  );
};

const HandQueue = (props) => {
  const [showDoneCards, {on, off}] = useToggle();
  const handleShowDoneCards = () => {
    completeOnboardingStep(ONBOARDING_STEPS.investigateDonePile);
    on();
  };

  return showDoneCards ? (
    <DonePile onHide={off} {...props} />
  ) : (
    <RealHandQueue onShowDoneCards={handleShowDoneCards} {...props} />
  );
};

export default HandQueue;
