import {memo, forwardRef, useState} from "react";
import {getVarName} from "@vanilla-extract/private";
import Uploader, {useUpload} from "../uploader";
import UnMarkedown from "../Markdown/UnMarkedown";
import Pill from "./Pill";
import xcolors from "../xui/xcolors";
import {deckStyles, deckVars} from "./deck.css";
import {XCol, XRow} from "../xui";
import {LinkOrButton, cx, uiStyles} from "@cdx/common";
import {getModalUrl} from "../ModalRegistry";
import {useLocation, useHistory} from "react-router-dom";
import {Draggable} from "@codecks/dnd";
import {useIsDraggingFile} from "../../lib/hooks/useFileDrop";
import {CdxCropImg, createResizeUrl} from "../CdxImg";
import {colorThemeVars} from "@cdx/ds/css/themes/color-themes.css";
import {Box, Col, DSIconButton, DSIconMedia, Row} from "@cdx/ds";
import {transitions} from "@cdx/ds/css/decoration.css";

const BottomPart = memo((props) => {
  const {
    dominantColor,
    completeCards,
    incompleteCards,
    title,
    leftSlot,
    rightSlot,
    tooltip,
    isSmall,
  } = props;

  return (
    <div className={deckStyles.bottomContainer} style={{borderColor: dominantColor}}>
      <XCol
        absolute
        inset="full"
        className={deckStyles.bottomTransBg}
        style={{backgroundColor: dominantColor}}
      />
      <XCol relative>
        <div
          className={cx(
            deckStyles.bottomTitleContainer.base,
            isSmall && deckStyles.bottomTitleContainer.isSmall
          )}
          style={{backgroundColor: dominantColor}}
        >
          <UnMarkedown
            as="h2"
            className={cx(deckStyles.title.base, isSmall && deckStyles.title.isSmall)}
            maxChars={35}
          >
            {title}
          </UnMarkedown>
        </div>
        <Col relative>
          <div
            className={cx(deckStyles.bottomRound.base, isSmall && deckStyles.bottomRound.isSmall)}
            style={{backgroundColor: dominantColor}}
          />
          <Row justify="center" px="8px" pb="6px" relative>
            {leftSlot ? (
              <div className={deckStyles.leftSlot}>{leftSlot}</div>
            ) : (
              <div style={{width: 22}} />
            )}
            <Row sp="4px" relative zIndex={1}>
              {(incompleteCards || !completeCards) && (
                <Pill
                  type="count"
                  size={isSmall ? "sm" : "lg"}
                  // bottom={numNewEntries ? <UnreadComment number={numNewEntries} /> : null}
                  tooltip={tooltip}
                  bigNumber={(incompleteCards || 0) > 99}
                >
                  {incompleteCards || "-"}
                </Pill>
              )}
              {completeCards > 0 && (
                <Pill
                  type="complete"
                  size={isSmall ? "sm" : "lg"}
                  // bottom={numNewEntries ? <UnreadComment number={numNewEntries} /> : null}
                  tooltip={tooltip}
                  bigNumber={completeCards > 99}
                >
                  {completeCards}
                </Pill>
              )}
            </Row>
            {rightSlot ? (
              <div className={deckStyles.rightSlot}>{rightSlot}</div>
            ) : (
              <div style={{width: 22}} />
            )}
          </Row>
        </Col>
      </XCol>
    </div>
  );
});

const CoverFallBack = ({editModal, emptyCoverLabel, ongoingUploads}) => {
  const location = useLocation();
  const history = useHistory();

  const handleAddImageClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(getModalUrl({...editModal, location}));
  };

  return (
    <>
      <Box colorTheme="gray750">
        <DSIconButton
          icon={<DSIconMedia />}
          variant="tertiary"
          size="sm"
          onClick={handleAddImageClick}
          label={emptyCoverLabel}
        />
      </Box>
      <Uploader.Uploads ongoingUploads={ongoingUploads} onDark className={deckStyles.uploader} />
    </>
  );
};

export const DeckLikeTileWithUploads = forwardRef(({dragInfo, ...rest}, ref) => {
  if (dragInfo) {
    const {containerProps, ...rest2} = rest;
    return (
      <Draggable type="DECK" id={dragInfo.id} itemData={dragInfo} mergeRef={ref}>
        {({handlers, ref: dragRef}) => (
          <InnerDeckLikeTileWithUploads
            {...rest2}
            fowardedRef={dragRef}
            containerProps={{...containerProps, ...handlers}}
          />
        )}
      </Draggable>
    );
  } else {
    return <InnerDeckLikeTileWithUploads {...rest} isNotDraggable fowardedRef={ref} />;
  }
});

const InnerDeckLikeTileWithUploads = (props) => {
  const {
    uploadKey,
    handleCoverImageUpload,
    canModify,
    emptyCoverLabel,
    children,
    editModal,
    dragInfo,
    fowardedRef,
    nonTransparentBackground,
    ...rest
  } = props;
  const {dropAreaProps, ongoingUploads} = useUpload({
    id: uploadKey,
    onUpload: handleCoverImageUpload,
  });

  const content = (
    <DeckLikeTile
      topChildren={
        <Uploader.Uploads ongoingUploads={ongoingUploads} onDark className={deckStyles.uploader} />
      }
      getCoverFallback={() =>
        canModify ? (
          <CoverFallBack
            editModal={editModal}
            emptyCoverLabel={emptyCoverLabel}
            ongoingUploads={ongoingUploads}
          />
        ) : null
      }
      {...rest}
      fowardedRef={fowardedRef}
    >
      {children}
      {canModify && (
        <Uploader.DropArea
          {...dropAreaProps}
          className={deckStyles.dropArea}
          label="drop cover image"
        />
      )}
    </DeckLikeTile>
  );
  if (nonTransparentBackground) {
    return (
      <Box rounded={8} className={deckStyles.nonTransparentBg}>
        {content}
      </Box>
    );
  } else {
    return content;
  }
};

const DeckLikeTile = (props) => {
  const {
    isSelected,
    to,
    onClick,
    coverFileUrl,
    completeCards,
    incompleteCards,
    isOtherSelected,
    dominantColor: rawDominantColor,
    isCoverFileLoaded,
    numNewEntries,
    deckNotiCount,
    title,
    children,
    leftSlot,
    rightSlot,
    cardCountTooltip,
    canDropCard,
    containerProps,
    isSmall,
    getCoverFallback,
    topChildren,
    fowardedRef,
    badges = [],
    style: passedStyle,
    isNotDraggable,
  } = props;
  const [hover, setHover] = useState(false);
  const dominantColor = hover ? xcolors.active : rawDominantColor;

  const isDraggingFile = useIsDraggingFile();

  const canDrop = canDropCard || isDraggingFile;

  const bgSrc =
    coverFileUrl && createResizeUrl({src: coverFileUrl, width: 15, height: 15, noAnim: true});

  const sum = completeCards + incompleteCards || 1;
  const factor = 1 - Math.exp(-sum / 100);

  const style = {
    ...passedStyle,
    [getVarName(colorThemeVars.text.primary)]: dominantColor,
    [getVarName(colorThemeVars.border.default)]: dominantColor,
    [getVarName(deckVars.completeCount)]: (completeCards / sum) * factor,
    [getVarName(deckVars.incompleteCount)]: (incompleteCards / sum) * factor,
  };

  const isEmpty = completeCards === 0 && incompleteCards === 0;

  return (
    <Box
      relative
      style={style}
      className={cx(
        transitions.colors,
        !canDrop && isOtherSelected && deckStyles.container.isOtherSelected,
        deckStyles.outerDeck.size[isSmall ? "small" : "default"]
      )}
    >
      <XCol
        as={LinkOrButton}
        to={to}
        onClick={onClick}
        className={cx(
          deckStyles.container.base,
          isSmall && deckStyles.container.isSmall,
          !canDrop && isEmpty && !isSelected && deckStyles.container.isEmpty
        )}
        style={bgSrc && {backgroundImage: `url('${bgSrc}')`}}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={fowardedRef}
        {...containerProps}
        {...(isNotDraggable ? {draggable: false} : null)}
      >
        {badges.length > 0 ? (
          <XRow absolute sp={0} className={deckStyles.badgeBox}>
            {badges}
          </XRow>
        ) : null}
        {children}
        <CdxCropImg
          src={coverFileUrl}
          width={isSmall ? 106 : 147}
          height={isSmall ? 49 : 104}
          className={deckStyles.topPart}
          style={{borderColor: dominantColor}}
          imgClassName={uiStyles.backgroundColor.gray900}
          isLoadingSrc={!isCoverFileLoaded}
          loadingCoverColor="rgba(50,50,50,0.8)"
          fallback={() => (
            <XCol sp={2} align="center">
              {getCoverFallback && getCoverFallback()}
            </XCol>
          )}
        />
        {topChildren}
        <BottomPart
          numNewEntries={numNewEntries}
          dominantColor={dominantColor}
          completeCards={completeCards}
          incompleteCards={incompleteCards}
          deckNotiCount={deckNotiCount}
          title={title}
          leftSlot={leftSlot}
          rightSlot={rightSlot}
          tooltip={cardCountTooltip}
          isSmall={isSmall}
        />
      </XCol>
      <div className={deckStyles.container.depth}>
        <div className={deckStyles.container.incompletedDepth} />
      </div>
    </Box>
  );
};

export default DeckLikeTile;
