import {useRoot, useInstance} from "../../lib/mate/mate-utils";
import {unsortedProjectTags, unsortedPersonalTags} from "../../lib/utils";
import routes from "../../routes";
import {cx} from "@cdx/common";
import {tagStyle} from "./markdown.css";
import memoizeOne from "memoize-one";
import {useHistory} from "react-router-dom";
import Emoji from "../../lib/emoji/EmojiRenderer";

/** @type: any */
export const TagStyle = ({onDark, onGray, type = "personal", className, ...rest}) => (
  <span
    className={cx(
      className,
      tagStyle.base,
      tagStyle.byType[type].base,
      tagStyle.byType[type][onDark ? "dark" : onGray ? "gray" : "light"]
    )}
    {...rest}
  />
);

const memoizedProjectTagFinder = memoizeOne((tags) => {
  const map = new Map(tags.map((t) => [t.tag.toLowerCase(), t]));
  return (tag) => map.get(tag.toLowerCase());
});
const alwaysNull = () => null;

export const getProjectTagFinder = (project) => {
  const tags = project && unsortedProjectTags(project);
  if (!tags) return alwaysNull;
  return memoizedProjectTagFinder(tags);
};

export const rawEnrichCardProjectTags = ({masterTags, project}) => {
  if (!masterTags || masterTags.length === 0) return [];
  const mapper = getProjectTagFinder(project);
  return masterTags.map((t) => {
    const tagObject = mapper(t);
    return tagObject
      ? {
          tag: t,
          orgTag: tagObject.tag,
          color: tagObject.$meta.get("color", null),
          emoji: tagObject.$meta.get("emoji", null),
          isDeprecated: false,
        }
      : {isDeprecated: true, tag: t};
  });
};

export const enrichCardProjectTags = (card) => {
  return rawEnrichCardProjectTags({
    masterTags: card.masterTags,
    project: card.deck && card.deck.project,
  });
};

export const ProjectTag = ({tagObject, onDark, onClick}) => {
  const emoji = tagObject.$meta ? tagObject.$meta.get("emoji", null) : tagObject.emoji;
  const color = tagObject.$meta ? tagObject.$meta.get("color", null) : tagObject.color;
  return (
    <TagStyle type="master" onDark={onDark} onClick={onClick}>
      {emoji ? (
        <div className={tagStyle.emojiPill}>
          <Emoji block>{emoji}</Emoji>
        </div>
      ) : color ? (
        <span className={tagStyle.tagPill} style={{backgroundColor: color}}>
          #
        </span>
      ) : null}
      <span className={cx(tagStyle.masterContent.base, onDark && tagStyle.masterContent.onDark)}>
        {!color && !emoji ? "#" : null}
        {tagObject.tag}
      </span>
    </TagStyle>
  );
};

export const PersonalTag = ({tag, onDark, onClick}) => (
  <TagStyle type="personal" onDark={onDark} onClick={onClick}>
    #{tag}
  </TagStyle>
);

export const OtherTag = ({tag, onDark, onClick, onGray}) => (
  <TagStyle type="other" onDark={onDark} onGray={onGray} onClick={onClick}>
    #{tag}
  </TagStyle>
);

const typeToCategory = {project: "prjct-tags", personal: "prsnl-tags", other: "tags"};

const InteractiveTag = ({comp: {Comp, props}, type, tagObject}) => {
  const history = useHistory();
  const handleClick = (e) => {
    history.push({
      pathname: routes.decks.getUrl(),
      state: {searchTokens: [{category: typeToCategory[type], value: tagObject.tag}]},
    });
    e.stopPropagation(); // to prevent click handler in e.g. deck tile
  };
  return <Comp {...props} onClick={handleClick} />;
};

const memoizedMyTagFinder = memoizeOne((myTags) => {
  const map = new Map(myTags.map((t) => [t.tag.toLowerCase(), t]));
  return (tag) => map.get(tag.toLowerCase());
});

const typeToComp = {
  project: {comp: ProjectTag, getProps: (tagObject) => ({tagObject})},
  personal: {comp: PersonalTag, getProps: (tagObject) => ({tag: tagObject.tag})},
  other: {comp: OtherTag, getProps: (tagObject) => ({tag: tagObject.tag})},
};

export const MagicTag = ({projectId, noOnClick, onDark, onGray, tag}) => {
  const {loggedInUser: me, account} = useRoot();
  const project = useInstance("project", projectId);
  const getType = () => {
    const projectTagFinder = getProjectTagFinder(project);
    const pt = projectTagFinder(tag);
    if (pt) return {type: "project", tagObject: pt};
    const myTagFinder = memoizedMyTagFinder(unsortedPersonalTags(me, account));
    const myT = myTagFinder(tag);
    if (myT) return {type: "personal", tagObject: myT};
    return {type: "other", tagObject: {tag}};
  };
  const {type, tagObject} = getType();
  const {comp: Comp, getProps} = typeToComp[type];
  const props = {...getProps(tagObject), onDark, onGray};

  return noOnClick ? (
    <Comp {...props} />
  ) : (
    <InteractiveTag comp={{Comp, props}} type={type} tagObject={tagObject} />
  );
};
