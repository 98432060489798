import {useSpring, animated} from "react-spring";
import {usePendingClick} from "../DSButton/DSButton";
import {ReactNode, forwardRef} from "react";
import {dsTagStyles as styles} from "./DSTag.css";
import cx from "../../utils/cx";

export type DSTagProps = {
  onMetaClick?: JSX.IntrinsicElements["button"]["onClick"];
  children: ReactNode;
  theme?: keyof typeof styles.theme | null;
  size?: keyof typeof styles.size;
} & Pick<
  JSX.IntrinsicElements["button"],
  "onClick" | "aria-label" | "className" | "style" | "autoFocus"
>;
export const DSTag = forwardRef<HTMLButtonElement, DSTagProps>((props, ref) => {
  const {children, onClick, onMetaClick, className, theme = null, size = "md", ...rest} = props;
  const {handleClick, icons, state} = usePendingClick({
    onClick,
    onMetaClick,
  });

  const contentStyles = useSpring({
    scale: state === "initial" ? 1 : 0.95,
    opacity: state === "initial" ? 1 : 0,
    config: {tension: 600, friction: 44},
  });

  return (
    <button
      className={cx(styles.base, styles.size[size], className, styles.theme[theme || "default"])}
      onClick={handleClick}
      ref={ref}
      {...rest}
    >
      {icons}
      <animated.div style={contentStyles}>{children}</animated.div>
    </button>
  );
});

export type NonInteractiveDSTagProps = Pick<
  DSTagProps,
  "size" | "theme" | "className" | "style" | "children"
>;

export const NonInteractiveDSTag = forwardRef<HTMLDivElement, NonInteractiveDSTagProps>(
  (props, ref) => {
    const {className, theme = null, size = "md", ...rest} = props;

    return (
      <div
        className={cx(
          styles.nonInteractiveBase,
          styles.size[size],
          className,
          styles.theme[theme || "default"]
        )}
        ref={ref}
        {...rest}
      />
    );
  }
);
