import {forwardRef, useCallback} from "react";
import FlipGroup from "react-flip-primitives";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import Ui from "../../components/ui2/index";
import {
  useClickOutside,
  springConfigs,
  XPlainButton,
  useToggle,
  rawButtonStyle,
  cx,
} from "@cdx/common";
import xcolors from "../../components/xui/xcolors";
import {MenuNudges} from "../nudges/MenuNudges";
import MenuNav from "./MenuNav";
import MenuProjects from "./MenuProjects";
import {arenaMenuStyles as styles, ARENA_MENU_WIDTH} from "./arena.css";
import cdxEnv from "../../env";
import {accessibleOrgRoles} from "../../lib/permissions";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";

const MenuHeader = ({onClose}) => (
  <button className={cx(rawButtonStyle, styles.menuHeader)} onClick={onClose}>
    <XRow sp={3} align="center" px={3} py={3}>
      <Ui.Icon.Joystick style={{fontSize: 28, color: xcolors.white}} />
      <XText color="white" preset="bold">
        Mission Control
      </XText>
      <XPush />
      <Ui.Icon.Close style={{fontSize: 16, color: xcolors.gray600}} />
    </XRow>
  </button>
);

const AccountPicker = ({root, roles, toggleShowAccounts, showAccounts}) => {
  const getButtonProps = () => {
    if (roles.length === 2) {
      const other = roles.find((r) => r.account !== root.account);
      return {href: cdxEnv.HOST_PATTERN.replace("[SUB]", other.account.subdomain)};
    } else {
      return {onClick: toggleShowAccounts};
    }
  };

  return (
    <XCol px={3} py={2} bg="gray700" sp={3}>
      <XRow align="end">
        <XCol sp={0}>
          <XText preset="bold" size={0} color="gray500">
            Current Organization
          </XText>
          <XText color="gray300" size={3}>
            {root.account.name}
          </XText>
        </XCol>
        <XPush />
        <XPlainButton color="white" active={showAccounts} {...getButtonProps()}>
          Switch
        </XPlainButton>
      </XRow>
      {showAccounts && (
        <XCol sp={1}>
          {roles
            .filter((r) => r.account !== root.account)
            .map((r, i) => (
              <XPlainButton
                color="whitish"
                key={r.account.$meta.get("id", i)}
                href={cdxEnv.HOST_PATTERN.replace("[SUB]", r.account.subdomain)}
              >
                <XCol align="start">
                  <XText size={3} color="gray200">
                    {r.account.name}
                  </XText>
                  <XText preset="bold" color="gray400" size={1}>
                    {cdxEnv.HOST_PATTERN.replace("[SUB]", r.account.subdomain).replace(
                      /https?:\/\//,
                      ""
                    )}
                  </XText>
                </XCol>
              </XPlainButton>
            ))}
        </XCol>
      )}
    </XCol>
  );
};

const MenuComp = forwardRef(({root, setOpen, location, history}, ref) => {
  const {loggedInUser} = root;
  const [showAccounts, {toggle: toggleShowAccounts}] = useToggle();
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const clickOutsideHandlers = useClickOutside(onClose, {strict: true});
  const roles = accessibleOrgRoles({root});
  const isUserLoaded = Boolean(loggedInUser?.$meta.isLoaded);
  return (
    <div className={styles.menuOuter} ref={ref} {...clickOutsideHandlers}>
      <XCol bg="gray800" elevation={2} className={cx(styles.menuInner, colorThemes.gray550)}>
        <MenuHeader onClose={onClose} />
        {isUserLoaded && <MenuNudges root={root} location={location} />}
        <XCol fillParent scrollable sp={4}>
          {roles.length > 1 && (
            <AccountPicker
              root={root}
              roles={roles}
              showAccounts={showAccounts}
              toggleShowAccounts={toggleShowAccounts}
            />
          )}
          {isUserLoaded && !showAccounts && <MenuProjects root={root} location={location} />}
          <XPush />
          <XCol sp={2}>
            {(isUserLoaded || !loggedInUser) && (
              <MenuNav root={root} location={location} history={history} />
            )}
            <XCol px={3} bg="gray900" py={0} align="center">
              <XText color="gray600" size={0}>
                v{cdxEnv.DEPLOYMENT_LABEL || "DEV"}
              </XText>
            </XCol>
          </XCol>
        </XCol>
      </XCol>
    </div>
  );
});

const ArenaMenu = ({children, isOpen, setOpen, location, root, history}) => (
  <>
    <FlipGroup
      changeKey={isOpen ? "t" : "f"}
      keysAndData={isOpen ? [{key: "open", data: null}] : []}
    >
      {(registerNode, keysAndData) =>
        keysAndData.map(({key}) => (
          <MenuComp
            key={key}
            root={root}
            setOpen={setOpen}
            ref={registerNode(key, {
              enterPosition: {transform: `translate(-${ARENA_MENU_WIDTH}, 0)`},
              leavePosition: {transform: `translate(-${ARENA_MENU_WIDTH}, 0)`},
              onPresence: (val) => ({opacity: val, pointerEvents: val > 0.7 ? "auto" : "none"}),
              positionSpringConfig: springConfigs.quickNoBounce,
              presenceSpringConfig: springConfigs.quickNoBounce,
            })}
            location={location}
            history={history}
          />
        ))
      }
    </FlipGroup>
    {children}
  </>
);

export default ArenaMenu;
