import {useState} from "react";
import {Draggable} from "@codecks/dnd";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import Ui from "../../components/ui2/index";
import xcolors from "../../components/xui/xcolors";
import {getModalUrl} from "../../components/ModalRegistry";
import {alphaSeq} from "../../lib/sequences";
import {api} from "../../lib/api";
import Uploader, {useUpload} from "../../components/uploader";
import XTextButton from "../../components/xui/XTextButton";
import {
  cx,
  IconProjectTiles,
  IconProjectTilesSingle,
  ToggleButton,
  TooltipForChild,
  uiStyles,
} from "@cdx/common";
import {projectTileStyles as styles} from "./arena.css";
import {CdxCropImgByFile} from "../../components/CdxImg";
import cdxEnv from "../../env";
import {
  hasPermissionToManageProject,
  hasPermissionToManageProjectTags,
} from "../../lib/permissions";
import {DSIconButton, DSIconCog, DSIconDrag} from "@cdx/ds";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";

export const InnerProjectTile = ({
  project,
  isSelected,
  location,
  onChangeProject,
  hideToggle,
  isLastSelected,
  root,
  onSelectSingleProject,
  onShowAllProjectsClick,
  noHideButton,
  innerRef,
  dragHandlers,
}) => {
  const [isWorking, setIsWorking] = useState(false);
  const bgMeta = project.coverFile && project.coverFile.$meta.get("meta", null);

  const canManageProject = hasPermissionToManageProject({root, project});
  const canManageProjectTags = hasPermissionToManageProjectTags({root, project});

  const tintCol = bgMeta && bgMeta.dominantColors && bgMeta.dominantColors[0].color;

  const handleCoverImageUpload = (data) =>
    api.mutate.projects.updateCover({
      id: project.id,
      coverFileData: data,
      userId: root.loggedInUser.id,
    });

  const {dropAreaProps, ongoingUploads} = useUpload({
    id: `projectCover-${project.id}`,
    onUpload: handleCoverImageUpload,
  });

  const handleChange = isLastSelected
    ? undefined
    : (checked) => {
        const res = onChangeProject({checked, projectId: project.id});
        if (res && res.then) {
          setIsWorking(true);
          res.then(() => setIsWorking(false));
        }
        return res;
      };

  const handleSingleSelect = isLastSelected
    ? undefined
    : () => {
        const res = onSelectSingleProject({projectId: project.id});
        if (res && res.then) {
          setIsWorking(true);
          res.then(() => setIsWorking(false));
        }
        return res;
      };

  return (
    <XCol
      relative
      noOverflow
      rounded="md"
      bg="gray600"
      elevation={1}
      className={cx(styles.tileBg, colorThemes.dark850HighContrast)}
      onClick={hideToggle || !handleChange ? undefined : () => handleChange(!isSelected)}
      {...dragHandlers}
      ref={innerRef}
    >
      <CdxCropImgByFile
        file={project.coverFile}
        asBackground
        width={300}
        // 58px in case the text breaks into two lines
        height={58}
        className={cx(!isSelected && styles.grey)}
        fallbackClassName={styles.fallback}
      />
      <XCol
        absolute
        inset="full"
        className={styles.tintBg}
        style={{backgroundColor: isSelected ? tintCol || xcolors.gray500 : xcolors.gray600}}
      />
      {canManageProject && <Uploader.DropArea {...dropAreaProps} label="Set as cover image" />}
      <XCol px={0} py={2} sp={1}>
        <XRow sp={0} align="center" relative pr={2}>
          <DSIconDrag size={24} className={uiStyles.color.gray300} />
          <XText
            color={isSelected ? "white" : "gray300"}
            preset="bold"
            className={styles.projectName}
          >
            {project.name}
          </XText>
          {project.isPublic && (
            <XTextButton
              tooltip={`This project is publicly visible. ${
                project.commentsArePublic
                  ? "Conversations are also public."
                  : "Conversations are not public."
              } Click to open public view.`}
              square
              size="sm"
              href={`${cdxEnv.OPEN_APP_HOST}/${project.publicPath}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Ui.Icon.Globe
                style={{color: xcolors.white, fontSize: 12, position: "relative", top: -1}}
              />
            </XTextButton>
          )}
          <XPush />
          <XRow sp={1} align="center">
            <div onClick={(e) => e.stopPropagation()}>
              {(canManageProject || canManageProjectTags) && (
                <TooltipForChild tooltip="Open Project Settings" delayed>
                  <DSIconButton
                    icon={<DSIconCog />}
                    negatePadding
                    variant="tertiary"
                    size="sm"
                    className={!noHideButton && styles.showOnHover}
                    to={getModalUrl({
                      location,
                      modal: `projectSettings.${alphaSeq.intToSeq(project.accountSeq)}`,
                    })}
                  />
                </TooltipForChild>
              )}
            </div>
            {!hideToggle && (
              <div onClick={(e) => e.stopPropagation()}>
                {isLastSelected ? (
                  <TooltipForChild tooltip="Show all projects" delayed>
                    <DSIconButton
                      icon={<IconProjectTiles size="sm" />}
                      variant="tertiary"
                      size="sm"
                      className={!noHideButton && styles.showOnHover}
                      disabled={isWorking}
                      onClick={onShowAllProjectsClick}
                      negatePadding
                    />
                  </TooltipForChild>
                ) : (
                  <TooltipForChild tooltip="Show only this project" delayed>
                    <DSIconButton
                      icon={<IconProjectTilesSingle size="sm" />}
                      variant="tertiary"
                      size="sm"
                      className={!noHideButton && styles.showOnHover}
                      disabled={isWorking}
                      onClick={handleSingleSelect}
                      negatePadding
                    />
                  </TooltipForChild>
                )}
              </div>
            )}
            {!hideToggle && (
              <ToggleButton
                tooltip={isSelected ? "Hide Project" : "Show Project"}
                tooltipProps={{hidden: isLastSelected, delayed: true}}
                checked={isSelected}
                onChange={handleChange}
                disabled={isLastSelected || isWorking}
                onDark
              />
            )}
          </XRow>
        </XRow>
        <Uploader.Uploads ongoingUploads={ongoingUploads} onDark />
      </XCol>
    </XCol>
  );
};

const ProjectTile = (props) => {
  const {project, hideToggle} = props;
  const id = project.$meta.get("id", null);

  return (
    <Draggable type="PROJECT" id={id} itemData={{id}} disabled={!id || hideToggle}>
      {({handlers, ref}) => <InnerProjectTile {...props} innerRef={ref} dragHandlers={handlers} />}
    </Draggable>
  );
};

export default ProjectTile;
